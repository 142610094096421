import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { WithPermission } from 'shared/WithPermission';

export const BoxConfigurationPage: React.FC<RouteComponentProps> = ({
  children,
}) => {
  return (
    <WithPermission
      permissions={['boxKnowledgeManagementConfigureAppAccess']}
      operation="all"
    >
      {children}
    </WithPermission>
  );
};

export { BoxConfigurationOverview } from './BoxConfigurationOverview';
export { BoxConfigureAppCredentials } from './BoxConfigureAppCredentials';
export { BoxConfigureRootFolder } from './BoxConfigureRootFolder';
