import React, { FC } from 'react';
import cx from 'classnames';
import { ChevronDown, ChevronUp } from 'shared/icons';
import { Button } from '@socialchorus/shared-ui-components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { MAIcon } from 'shared/MAIcon';
import styles from './expand-contract-pill.module.css';

export const ExpandContractPill: FC<{
  buttonLabel: string;
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
}> = ({ children, buttonLabel, isOpen, toggle, close }) => {
  const clickAreaRef = React.useRef(null);
  useOnClickOutside(clickAreaRef, close);

  return (
    <div ref={clickAreaRef} className={styles.RelativeWrapper}>
      <Button
        className={cx(styles.Rounded, styles.PillButton)}
        onClick={toggle}
        label={buttonLabel}
        size="compact"
        leftIcon={<MAIcon name="warning" />}
        rightIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
        variant="danger"
      />
      <div className={styles.AbsoluteChild}>{isOpen && children}</div>
    </div>
  );
};
