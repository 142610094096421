import React, { useState } from 'react';
import { PersonalizationInput } from 'App/Program/Editors/Publisher/Deliver/Notifications/PersonalizationInput';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Caption, Text } from 'DesignSystem/Typography';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { useUniqueId } from 'hooks/useUniqueId';
import {
  Avatar,
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  Icon,
} from '@socialchorus/shared-ui-components';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import { InfiniteScrollList } from 'shared/InfiniteList';
import { EmailSenderAlias } from 'models/publisher/settings';
import { EmailAlias } from 'models/email-alias';
import { useAuthorsList } from 'hooks/useAuthorsList';
import { EmailData } from 'services/api-email-alias';
import styles from './inputs.module.css';

export const TextInputContext = React.createContext<
  typeof TextInputField | null
>(null);

export type TextInputFieldProps = {
  value: string | undefined;
  label: string;
  placeholder?: string;
  inputPrefixText?: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
};

const maxSize = MAX_NOTIFICATION_LENGTH;

export function TextInputField({
  value = '',
  label,
  placeholder,
  inputPrefixText,
  onChange,
  disabled = false,
  errorMessage,
  id,
}: TextInputFieldProps): JSX.Element {
  const generatedId = useUniqueId();
  const elId = id ?? generatedId;
  return (
    <Flex alignStart column>
      <Label
        htmlFor={elId}
        id={`${elId}__label`}
        style={{ paddingBottom: '4px' }}
      >
        {label}
      </Label>
      <PersonalizationInput
        errorMessage={!!errorMessage}
        inputPrefixText={inputPrefixText}
        maxSize={maxSize}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        id={elId}
        border="light"
      />
      <Flex className={styles.errorsContainer} spread>
        <Flex>
          {errorMessage && (
            <div className={styles.error} role="alert">
              {errorMessage}
            </div>
          )}
        </Flex>
        <Caption>
          {value.length} / {maxSize} characters
        </Caption>
      </Flex>
    </Flex>
  );
}

export function TestTextInputField({
  label,
  value,
  onChange,
  placeholder,
  id,
  disabled,
}: TextInputFieldProps): JSX.Element {
  const generatedId = useUniqueId();
  const elId = id ?? generatedId;
  const labelId = `${elId}__label`;
  return (
    <>
      <label htmlFor={elId} id={labelId} style={{ paddingBottom: '4px' }}>
        {label}
      </label>
      <input
        type="text"
        id={elId}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        aria-labelledby={labelId}
      />
    </>
  );
}

export type LabelProps = Omit<
  React.ComponentPropsWithoutRef<'label'>,
  'className'
>;
export function Label({ children, ...otherProps }: LabelProps): JSX.Element {
  return (
    <Text
      as="label"
      className={{
        Body: true,
        Semibold: true,
      }}
      {...otherProps}
    >
      {children ? <span>{children}</span> : null}
    </Text>
  );
}

function selectEmailAlias(data: EmailData) {
  const alias = data.attributes;
  const display = senderNameAndEmail(alias);
  return {
    id: data.id,
    ...alias,
    parsedName: alias.default ? `Default - ${display}` : `${display}`,
    withoutDefault: `${display}`,
  };
}

const senderNameAndEmail = (
  alias: Pick<EmailAlias, 'senderName' | 'senderEmail'>
) => `${alias.senderName} <${alias.senderEmail}>`;

function getEmailAliasFromValue(
  emailAliases: ReturnType<typeof selectEmailAlias>[],
  id: EmailAlias['id'] | undefined
) {
  if (!id) {
    return undefined;
  }
  return emailAliases.find((alias) => alias.id === id);
}

export function EmailSenderAliasCombobox({
  emailAlias,
  onAliasSelect,
  disabled = false,
}: {
  emailAlias: EmailSenderAlias;
  onAliasSelect: (emailAlias: EmailSenderAlias) => void;
  disabled?: boolean;
}): JSX.Element {
  const { id: programId } = useProgram();
  // the unique identifying value, usually `id` as a string
  const [selectedValue, setSelectedValue] = useState<
    EmailAlias['id'] | undefined
  >(undefined);

  const [query, setQuery] = useState<string | undefined>(undefined);
  const debouncedQuery = useDebounce(query);
  const {
    isLoading,
    data: emailAliasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    meta,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: query ? debouncedQuery : undefined,
    status: ['active'],
    perPage: 25,
    selectData: selectEmailAlias,
  });

  const selectedAlias = getEmailAliasFromValue(emailAliasData, selectedValue);
  const comboboxValue = selectedAlias ? selectedAlias.parsedName : undefined;
  const id = useUniqueId();
  const labelId = `${id}__label`;

  return (
    <>
      <div>
        <Label id={labelId} htmlFor={id}>
          Email Alias
        </Label>
      </div>
      <Combobox
        autoSelect
        autoComplete="none"
        id={id}
        aria-labelledby={labelId}
        placeholder="Select an email alias"
        value={comboboxValue}
        onChange={(val) => {
          setQuery(val);
          if (!val) {
            // always reset the selected value if we remove all text input
            setSelectedValue(undefined);
          }
        }}
        onItemSelect={(itemId) => {
          const alias = getEmailAliasFromValue(emailAliasData, itemId);
          if (alias) {
            onAliasSelect(alias);
            setSelectedValue(itemId);
          }
        }}
        onBlur={() => {
          // onBlur ensure selection, and reset query
          setSelectedValue(emailAlias.id);
          setQuery(undefined);
        }}
        disabled={disabled}
        leftWidget={<Icon size={24}>search</Icon>}
        defaultValue={senderNameAndEmail(emailAlias)}
        popoverAsChild
      >
        <ComboboxPopover className={styles.comboboxPopover} gutter={4}>
          <InfiniteScrollList
            onEnd={hasNextPage ? fetchNextPage : undefined}
            isLoadingMore={isLoading || isFetchingNextPage}
            isReachingEnd={!hasNextPage}
            itemCount={meta?.totalRecords ?? 0}
          >
            {emailAliasData.map((emailAliasItem) => (
              <ComboboxItem
                key={emailAliasItem.id}
                value={emailAliasItem.id}
                setValueOnClick={false}
              >
                {emailAliasItem.parsedName}
              </ComboboxItem>
            ))}
          </InfiniteScrollList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
}

export type DesignAuthor = {
  displayName: string;
  avatarUrl?: string;
};
function getAuthorFromValue(
  authors: DesignAuthor[],
  initialAuthor: DesignAuthor | undefined,
  stringAuthor: string | undefined
) {
  if (!stringAuthor) {
    return undefined;
  }
  if (initialAuthor) {
    authors.push(initialAuthor);
  }
  return authors.find((a) => authorValue(a) === stringAuthor);
}
export function AuthorCombobox({
  author,
  onAuthorSelect,
  disabled = false,
}: {
  author: DesignAuthor | undefined;
  onAuthorSelect: (author: DesignAuthor) => void;
  disabled?: boolean;
}): JSX.Element {
  // the unique identifying value, usually `id` as a string
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );
  const [query, setQuery] = useState<string | undefined>(undefined);
  const debouncedQuery = useDebounce(query);
  const {
    isLoading,
    data: authorData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    meta,
  } = useAuthorsList(query ? debouncedQuery : '', false);
  const itemCount = Math.max(authorData.length, meta?.totalRecords ?? 0);
  const id = useUniqueId();
  const labelId = `${id}__label`;

  const selectedAuthor = getAuthorFromValue(authorData, author, selectedValue);
  const comboboxValue = selectedAuthor ? selectedAuthor.displayName : undefined;

  let widget: React.ReactNode;
  if (author) {
    widget = <Avatar size="small" shape="circle" imgSrc={author?.avatarUrl} />;
  }
  if (selectedAuthor) {
    widget = (
      <Avatar size="small" shape="circle" imgSrc={selectedAuthor?.avatarUrl} />
    );
  }
  if (typeof query === 'string' && selectedAuthor === undefined) {
    widget = <Icon size={24}>search</Icon>;
  }
  return (
    <>
      <div>
        <Label id={labelId} htmlFor={id}>
          Author
        </Label>
      </div>
      <Combobox
        autoSelect
        id={id}
        aria-labelledby={labelId}
        autoComplete="none"
        placeholder="Select an author"
        value={comboboxValue}
        onChange={(val) => {
          setQuery(val);
          if (!val) {
            // always reset the selected value if we remove all text input
            setSelectedValue(undefined);
          }
        }}
        onItemSelect={(stringifiedAuthor) => {
          const a = getAuthorFromValue(authorData, author, stringifiedAuthor);
          if (a) {
            setSelectedValue(stringifiedAuthor);
            onAuthorSelect(a);
          }
        }}
        onBlur={() => {
          // onBlur ensure selection, and reset query
          if (author) {
            setSelectedValue(authorValue(author));
            setQuery(undefined);
          }
        }}
        onCancel={() => {
          if (author) {
            onAuthorSelect(author);
          }
        }}
        disabled={disabled}
        defaultValue={author?.displayName}
        leftWidget={widget}
        popoverAsChild
      >
        <ComboboxPopover className={styles.comboboxPopover} gutter={4}>
          <InfiniteScrollList
            onEnd={fetchNextPage}
            isLoadingMore={isLoading || isFetchingNextPage}
            isReachingEnd={!hasNextPage}
            itemCount={itemCount}
          >
            {authorData?.map((authorItem) => (
              <ComboboxItem
                key={JSON.stringify(authorItem)}
                setValueOnClick={false}
                leftWidget={
                  <Avatar
                    size="small"
                    shape="circle"
                    imgSrc={authorItem.avatarUrl}
                  />
                }
                value={authorValue(authorItem)}
              >
                {authorItem.displayName}
              </ComboboxItem>
            ))}
          </InfiniteScrollList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
}

function authorValue(a: DesignAuthor) {
  return a.avatarUrl + a.displayName;
}
