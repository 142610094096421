import cx from 'classnames';
import React from 'react';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import styles from './shimmer-loading.module.css';

export const ShimmerLoading: React.FC<{ dashboardName: string }> = ({
  dashboardName,
}) => {
  const dashboards: { [name: string]: () => JSX.Element } = {
    Campaigns: () => <Campaigns />,
    Initiatives: () => <Initiatives />,
    Community: () => <Community />,
  };
  const dashboard = dashboards[dashboardName];
  return dashboard ? dashboard() : <DefaultLoading />;
};

export const Initiatives: React.FC = () => {
  return (
    <div className={styles.shimmerContainer}>
      {/* Top metrics row */}
      <div
        className={styles.metricsRow}
        style={{ '--columns': 4 } as React.CSSProperties}
      >
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
      </div>
      {/* Charts row */}
      <div className={styles.chartsRow}>
        <div className={styles.chartCard}>
          <div className={styles.chartHeader}>
            <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
            <div className={styles.chartControls}>
              <div className={cx(styles.control, styles.shimmerEffect)} />
              <div className={cx(styles.control, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={cx(styles.funnelChart, styles.shimmerEffect)} />
        </div>
        <div className={styles.chartCard}>
          <div className={styles.chartHeader}>
            <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
          </div>
          <div className={cx(styles.sentimentChart, styles.shimmerEffect)} />
        </div>
      </div>

      {/* Bottom metrics */}
      <div className={styles.metricsRow}>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
            <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
            <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
            <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Community: React.FC = () => {
  return (
    <div className={styles.metricsSection}>
      <div
        className={styles.metricsRow}
        style={{ '--columns': 2 } as React.CSSProperties}
      >
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
        <div className={cx(styles.metricCard, styles.small)}>
          <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
          <div className={styles.metricContent}>
            <div className={cx(styles.metricValue, styles.shimmerEffect)} />
          </div>
        </div>
      </div>
      <div
        className={styles.metricsRow}
        style={{ '--columns': 1 } as React.CSSProperties}
      >
        <div className={styles.chartCard}>
          <div className={styles.chartHeader}>
            <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
          </div>
          <div className={cx(styles.sentimentChart, styles.shimmerEffect)} />
        </div>
      </div>
      <div className={styles.chartsRow}>
        <div className={styles.chartCard}>
          <div className={styles.chartHeader}>
            <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
          </div>
          <div className={cx(styles.listChart, styles.shimmerEffect)} />
        </div>
        <div className={styles.chartCard}>
          <div className={styles.chartHeader}>
            <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
          </div>
          <div className={cx(styles.listChart, styles.shimmerEffect)} />
        </div>
      </div>
    </div>
  );
};

export const Campaigns: React.FC = () => {
  return (
    <div className={styles.shimmerContainer}>
      {/* Effectiveness section */}
      <div className={styles.metricsSection}>
        <div className={styles.headerText}>
          <div className={cx(styles.headerShimmer, styles.shimmerEffect)} />
        </div>
        <div className={styles.metricsRow}>
          <div className={styles.metricCard}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={styles.metricCard}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={styles.metricCard}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
        </div>
        <div className={styles.chartsRow}>
          <div className={styles.chartCard}>
            <div className={styles.chartHeader}>
              <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
              <div className={styles.chartControls}>
                <div className={cx(styles.control, styles.shimmerEffect)} />
                <div className={cx(styles.control, styles.shimmerEffect)} />
              </div>
            </div>
            <div className={cx(styles.funnelChart, styles.shimmerEffect)} />
          </div>
          <div className={styles.chartCard}>
            <div className={styles.chartHeader}>
              <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
              <div className={cx(styles.aiLabel, styles.shimmerEffect)} />
            </div>
            <div className={cx(styles.sentimentChart, styles.shimmerEffect)} />
          </div>
        </div>
      </div>

      {/* Reach Section */}
      <div className={styles.metricsSection}>
        <div className={styles.headerText}>
          <div className={cx(styles.headerShimmer, styles.shimmerEffect)} />
        </div>
        <div
          className={styles.metricsRow}
          style={{ '--columns': 4 } as React.CSSProperties}
        >
          <div className={cx(styles.metricCard, styles.small)}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={cx(styles.metricCard, styles.small)}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={cx(styles.metricCard, styles.small)}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
          <div className={cx(styles.metricCard, styles.small)}>
            <div className={cx(styles.metricTitle, styles.shimmerEffect)} />
            <div className={styles.metricContent}>
              <div className={cx(styles.metricValue, styles.shimmerEffect)} />
              <div className={cx(styles.metricPercent, styles.shimmerEffect)} />
            </div>
          </div>
        </div>
        <div className={styles.chartsRow}>
          <div className={styles.chartCard}>
            <div className={styles.chartHeader}>
              <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
            </div>
            <div className={cx(styles.sentimentChart, styles.shimmerEffect)} />
          </div>
          <div className={styles.chartCard}>
            <div className={styles.chartHeader}>
              <div className={cx(styles.chartTitle, styles.shimmerEffect)} />
              <div className={cx(styles.aiLabel, styles.shimmerEffect)} />
            </div>
            <div className={cx(styles.sentimentChart, styles.shimmerEffect)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Shimmer: React.FC<{ height: number; width: number }> = ({
  height,
  width,
}) => {
  return <div className={styles.shimmer} style={{ height, width }} />;
};

export default Shimmer;
