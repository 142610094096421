import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useProgram } from 'contexts/program';
import { getRootFolderConfig } from 'services/api-box-mangement';

export const getBoxRootFolderQueryKey = (programId: number): QueryKey => [
  'box-km-root-folder',
  programId,
];

type RootFolderData = { rootFolderId: number };

type UseBoxConfigurationStatusOptions = UseQueryOptions<RootFolderData>;

export function useBoxRootFolder(
  options?: UseBoxConfigurationStatusOptions
): UseQueryResult<RootFolderData> {
  const { id: programId } = useProgram();

  return useQuery(
    getBoxRootFolderQueryKey(programId),
    () => getRootFolderConfig(programId),
    options
  );
}
