import React from 'react';

import { NewFilterButton } from 'DesignSystem/Table/NewFilterButton';
import { Filter as FilterType } from 'models/language-controls/filter';
import { MAIcon } from 'shared/MAIcon';
import NestableMenu from 'shared/NestableMenu';
import { SelectFilter } from './SelectFilter';
import { DateFilter } from './DateFilter';
import { CreatorFilter } from './CreatorFilter';
import styles from './filters.module.css';

type PropsType = {
  filters: FilterType[];
  onChange: (filters: FilterType[]) => void;
};

export const Filters: React.FC<PropsType> = ({ filters, onChange }) => {
  const inactiveFilters = filters.filter((filter) => !filter.active);

  const handleFilterChange = (filter: FilterType) => {
    onChange(filters.map((f) => (f.name === filter.name ? filter : f)));
  };

  const setFilterVisibility = (active: boolean) => (filter: FilterType) =>
    handleFilterChange({
      ...filter,
      active,
    });

  return (
    <div className={styles.filtersWrapper}>
      {!!inactiveFilters.length && (
        <NewFiltersButton
          filters={inactiveFilters}
          onSelect={setFilterVisibility(true)}
        />
      )}
      {filters
        .filter((f) => f.active)
        .map((filter) => (
          <Filter
            key={filter.name}
            filter={filter}
            onChange={handleFilterChange}
            onRemove={setFilterVisibility(false)}
          />
        ))}
    </div>
  );
};

const Filter: React.FC<{
  filter: FilterType;
  onChange: (filter: FilterType) => void;
  onRemove?: (filter: FilterType) => void;
}> = ({ filter, onChange }) => {
  switch (filter.type) {
    case 'select':
      return filter.name === 'creator' ? (
        <CreatorFilter filter={filter} onChange={onChange} />
      ) : (
        <SelectFilter filter={filter} onChange={onChange} />
      );
    case 'date':
      return <DateFilter filter={filter} onChange={onChange} />;
    default:
      return null;
  }
};

const NewFiltersButton: React.FC<{
  filters: FilterType[];
  onSelect: (filter: FilterType) => void;
}> = ({ filters, onSelect }) => {
  const rootMenuData = {
    simpleMenu: {
      onSelect,
      items: filters.map((filter) => ({
        label: filter.label,
        key: filter.name,
        value: filter,
      })),
    },
  };

  const menuButton = (
    <div>
      <NewFilterButton
        icon={<MAIcon name="filter_list" />}
        className={styles.newFilterButton}
      />
    </div>
  );

  return <NestableMenu menuButton={menuButton} rootMenuData={rootMenuData} />;
};
