import React, { useMemo, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useProgram } from 'contexts/program';
import { Box } from 'DesignSystem/Components/Box';
import {
  Caption,
  Span,
  styles as typographyStyles,
} from 'DesignSystem/Typography';
import { Pills } from 'DesignSystem/Components/Pills';
import { Audience } from 'models/audience';
import { Button } from 'DesignSystem/Form/InputElements';
import { pluralize } from 'utility/text';
import { retriesDisabled, hasAudience } from 'models/publisher/settings';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useAudienceList } from 'components/publisher/settings/SettingsEditor/sections/TargetSelect/useAudienceList';
import { DismissType } from 'DesignSystem/Components/ClickDropdown';
import { Priority } from 'models/priority';
import icon from 'assets/images/orchestrate-direct-delivery.png';
import { useCampaignTarget } from 'components/publisher/settings/SettingsEditor/useCampaignTarget';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { ReactComponent as CircleCheckIcon } from 'shared/icons/CheckCircle.svg';
import { ReactComponent as WarningIcon } from 'shared/icons/WarningTriangle.svg';
import {
  GreenTintDarkPillClass,
  OrangeTintDarkPillClass,
} from 'DesignSystem/Components/Pill';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  useNotificationCenterEnabled,
  useNotificationCenterLabel,
} from 'hooks/notification-center';
import { AddAudiences } from '../AddAudiences';
import { ReTargeting } from './Retargeting';
import { RetargetingNotificationCenter } from './Retargeting/NotificationCenterRetargeting';
import styles from './DirectDeliverySection.module.css';
import { ChannelsSection } from '../../Channels';
import { EngagementBoostSection } from './EngagementeBoostSection';
import { SectionContainer } from '../../SettingsCard/SectionContainer';
import { ContentTitle, SettingsCard } from '../../SettingsCard';
import { InfoTooltip } from '../../Shared/InfoTooltip';
import { useChannelSettings } from '../../Channels/useChannelSettings';
import { archivedAudiences } from '../../Shared/common-utils';

interface DirectDeliverySectionProps {
  disabled?: boolean;
}

interface SummaryPill {
  id: number;
  enabled: boolean;
  text: React.ReactNode;
}

export const DirectDeliverySection = ({
  disabled,
}: DirectDeliverySectionProps): JSX.Element => {
  const { id: programId } = useProgram();
  const {
    settings,
    permissions: { audienceAccess },
    contentPermissions: { canEdit },
  } = useSettings();

  const limitVisibilityToAudience = !!useFeatureFlagsQuery(
    programId,
    'Studio.Content.Targets.LimitVisibilityToAudience'
  ).data?.value;

  const hideUserCount = !!useFeatureFlagsQuery(
    programId,
    'Audience.List.HideUserCount'
  ).data?.value;

  const { deliveryChannelsOptions } = useChannelSettings();
  const { audiences: targetAudiences } = useCampaignTarget();

  const Bold = Span(typographyStyles.Bold);
  const { notifications } = settings;
  const withAudience = hasAudience(settings);
  const noRetries = retriesDisabled(settings);

  const showSubjectLine = useMemo(
    () => settings.deliveryChannels.push || settings.deliveryChannels.email,
    [settings]
  );

  const addAudiences = (
    dismiss: DismissType,
    setDismissable: (dismissable: boolean) => void
  ) => (
    <AddAudiences
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
      hideUserCount={hideUserCount}
    />
  );

  const renderVisibilityPill = ({ text }: SummaryPill) => <>{text}</>;

  const renderVisibilityPillIcon = ({ enabled }: SummaryPill) =>
    enabled ? <WarningIcon /> : <CircleCheckIcon />;

  const renderVisibilityPillClass = ({ enabled }: SummaryPill) =>
    enabled ? OrangeTintDarkPillClass : GreenTintDarkPillClass;

  const visibilityPills: SummaryPill[] = [
    {
      id: 0,
      enabled: settings.limitVisibilityToAudience,
      text: settings.limitVisibilityToAudience
        ? 'Content visibility limited to selected Audience members only'
        : 'Content visible to Topic followers and selected Audiences',
    },
  ];

  const renderAudiencePill = (value: Audience) => (
    <>
      {value.title || value.name}&nbsp;
      {canEdit && !hideUserCount && (
        <>
          <Bold>·</Bold>&nbsp;{value.totalUsers}
        </>
      )}
    </>
  );

  const renderPriorityPill = (value: Priority) => <>{value.label}</>;

  const userLabel = () => {
    if (targetAudiences.isLoading) {
      return <LoadingSpinner size="small" />;
    }

    const count = targetAudiences.users?.totalObjects || 0;
    return `${count} ${pluralize(count, 'user')}`;
  };

  const audiencesData = useAudienceList([], '', () => {}, []);
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const [editing, setEditing] = useState(false);
  const notificationCenterLabel = useNotificationCenterLabel();

  const isCommRetriable = notifications.length > 1 || !noRetries;

  const RetargetingComponent = notificationCenterEnabled
    ? RetargetingNotificationCenter
    : ReTargeting;
  return (
    <>
      {audienceAccess && (
        <SectionContainer
          icon={icon}
          caption={`Select audience(s) to notify users via email, push, or ${notificationCenterLabel}.`}
          title="Direct Delivery"
          smallHeader={withAudience}
          isLoading={audiencesData.isLoading}
        >
          <SettingsCard
            editRenderProp={addAudiences}
            isEditing={false}
            showEditButton={withAudience}
            disabled={disabled}
            dataTest="direct-delivery-settings"
            onEditingToggle={(value: boolean) => {
              setEditing(value);
            }}
            editingMargin={[!withAudience ? 55 : 30, 32, 15, 32]}
          >
            {(toggleEditing: () => void) => (
              <>
                {!withAudience && !editing && (
                  <Box>
                    <Button
                      onClick={() => {
                        datadogRum.addAction('add_audiences');
                        toggleEditing();
                      }}
                      label="Add Audiences"
                      disabled={disabled}
                    />
                  </Box>
                )}

                <Box margin={[32, 0, 0, 0]}>
                  <>
                    {withAudience && (
                      <Box>
                        <ContentTitle>Audiences</ContentTitle>
                        <Pills
                          dataTest="audiences-pills"
                          values={settings.audiences}
                          render={renderAudiencePill}
                          valueTransformer={archivedAudiences}
                        />
                        {canEdit && (
                          <Box>
                            <Caption>{userLabel()}</Caption>
                          </Box>
                        )}
                        {limitVisibilityToAudience && (
                          <Box margin={[24, 0, 0, 0]}>
                            <Pills
                              small
                              values={visibilityPills}
                              render={renderVisibilityPill}
                              pillIcon={renderVisibilityPillIcon}
                              pillClassName={renderVisibilityPillClass}
                              dataTest="visibility-pills"
                              padding={[0, 8]}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                </Box>
                {settings.priority && withAudience && (
                  <Box margin={[32, 0, 0, 0]}>
                    <Box>
                      <ContentTitle>
                        Priority
                        <InfoTooltip message="The priority of your campaign helps determine the timing, channels, and frequency of deliveries for your campaign." />
                      </ContentTitle>
                      <Pills
                        dataTest="priority-pills"
                        values={[
                          { ...settings.priority, id: settings.priority.value },
                        ]}
                        small
                        render={renderPriorityPill}
                        pillClassName={styles.Priority}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </SettingsCard>

          {withAudience && (
            <>
              {deliveryChannelsOptions.length > 0 && (
                <ChannelsSection disabled={disabled} />
              )}
              <EngagementBoostSection disabled={disabled} />
            </>
          )}

          {/* if no configured retargetting notification && Engagement Boost OFF, then hide the retargeting notif text section */}
          {withAudience && showSubjectLine && isCommRetriable && (
            <RetargetingComponent disabled={Boolean(disabled)} />
          )}
        </SectionContainer>
      )}
    </>
  );
};
