import { RouteComponentProps, useParams } from '@reach/router';
import cx from 'classnames';
import { useProgram } from 'contexts/program';
import { PageHeader, PageTemplate } from 'DesignSystem/Layout/Pages';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { fetchTableauToken } from 'services/api-insights';
import TableauEmbed from '../../../../../components/tableauEmbed';
import { Box } from '../../../../../DesignSystem/Components';
import { Flex } from '../../../../../DesignSystem/Layout/Flex';
import { useDataJobMetrics } from '../../../../../hooks/data-jobs';
import { useWorkbookQuery } from '../../../../../hooks/insights/useInsightsPlusApi';
import { formatDate } from '../../../../../shared/Charts/Util';
import { displayLargestTimeInterval } from '../../../../../utility/datetimes';
import { UserActivityStreamCard } from '../../ContentResults/ContentResultsPageV2/MetricsCards/UserActivityStreamCard';
import { DashboardFilterBar } from '../components/DashboardFilterBar';
import { DownloadDashboardPdf } from '../components/DownloadWorksheetData';
import { ShimmerLoading } from '../components/ShimmerLoading';
import styles from './tableau.module.css';

export type TableauPropsType = {
  workbookId?: string;
  id?: string;
  path: string;
} & RouteComponentProps;

// Preload font which is embedded into the tableau reports to avoid FOUC (flash of unloaded content)
const customFontDomain = `${process.env.REACT_APP_TABLEAU_DOMAIN}/custom-fonts/webfont.html`;

export const Tableau: React.FC<TableauPropsType> = () => {
  const { dashboard_id: dashboardId, workbook_id: workbookId } = useParams();
  const { results: dataFreshnessResults } = useDataJobMetrics(workbookId);
  const programId = useProgram().id;
  const vizRef = useRef<Tableau.ITableauViz>(null);

  const [initialized, setInitialized] = useState(false);
  const [contentUrl, setContentUrl] = useState<string>();

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [tableauVizLoading, setTableauVizLoading] = useState(true);

  const [dashboardName, setDashboardName] = useState<string>();

  const [filters, setFilters] = useState<{ [key: string]: string[] }>({});
  const [activeSheet, setActiveSheet] = useState<Tableau.WorkSheet>();

  const { isLoading: workbookLoading, workbook } = useWorkbookQuery(
    programId,
    workbookId
  );

  const scrollPositionRef = useRef<number>(0);
  const selectedDashboard = useMemo(
    () => workbook?.views.view.find((d) => d.id === dashboardId),
    [dashboardId, workbook?.views.view]
  );

  // Load required token and dashboard for the tableau viz.
  const { isLoading: tokenLoading, isError, data: token } = useQuery(
    ['load-tableauEmbed', programId, workbookId],
    async (): Promise<string> => {
      return fetchTableauToken(programId);
    },
    { cacheTime: 0 }
  );

  const tabs =
    workbook?.views.view.map((db) => ({
      to: `${db.id}`,
      label: db.name,
    })) ?? undefined;

  const handleTabSwitched = (_name?: Tableau.CustomTableauEvent<unknown>) => {
    const tempActiveSheet = vizRef.current?.workbook.activeSheet;
    if (tempActiveSheet?.name !== selectedDashboard?.name) {
      setActiveSheet(tempActiveSheet);
      activateSheet(selectedDashboard?.name ?? '');
    } else {
      setTableauVizLoading(false);
    }
  };

  const activateSheet = React.useCallback((name: string) => {
    setTableauVizLoading(true);
    vizRef.current?.workbook.activateSheetAsync(name).then((sheet) => {
      if (sheet?.size.maxSize) {
        const styleString = `height: ${sheet.size.maxSize.height}px; width: ${sheet.size.maxSize.width}px; border: none;`;
        // Required to resize iframe when switching between tabs
        // (tableau adds inline styles that have to be overridden)
        vizRef.current?.iframe?.setAttribute('style', styleString);
      }
      document.querySelector('main')?.scrollTo(0, 0);
    });
  }, []);

  // To avoid rerender of entire Viz component, only set the contentUrl load once the workbook is loaded. Tableau
  // does not require it to be updated when switching between dashboards.
  useEffect(() => {
    if (workbook && !initialized) {
      scrollPositionRef.current = window.scrollY;
      setContentUrl(
        workbook?.views.view.find((d) => d.id === dashboardId)?.contentUrl
      );
      setInitialized(true);
    }
  }, [workbook, setContentUrl, initialized, setInitialized, dashboardId]);

  useEffect(() => {
    if (!selectedDashboard) return;
    if (dashboardName !== selectedDashboard.name) {
      setDashboardName(selectedDashboard.name);
      if (!tableauVizLoading && vizRef.current?.workbook !== undefined) {
        // switch viz to the currently selected dashboard
        activateSheet(selectedDashboard.name);
      }
    }
  }, [
    workbook,
    tableauVizLoading,
    dashboardId,
    activateSheet,
    selectedDashboard,
    dashboardName,
  ]);

  const onFilterChange = (filter: string, values: string[]) => {
    try {
      setFiltersLoading(true);
      let updateType = 'replace';
      if (!values.length) updateType = 'all';
      vizRef.current?.workbook.activeSheet
        .applyFilterAsync(filter, values, updateType)
        .then(() => {
          setFiltersLoading(false);
        });
      setFilters((f) => {
        const next = f;
        next[filter] = values;
        return next;
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to change filters', e);
      setFiltersLoading(false);
    }
  };

  const onParameterChanged = (
    parameter: string,
    value: string | Date | number | boolean
  ) => {
    try {
      setFiltersLoading(true);
      vizRef.current?.workbook
        .changeParameterValueAsync(parameter, value)
        .then(() => {
          setFiltersLoading(false);
        });
    } catch (e) {
      setFiltersLoading(false);
    }
  };

  const dashboardRefreshTimeInterval = useMemo(() => {
    if (!dataFreshnessResults?.dataFreshnessDate) {
      return null;
    }
    const duration = Interval.fromDateTimes(
      dataFreshnessResults?.dataFreshnessDate,
      DateTime.now().toUTC()
    ).toDuration(['days', 'hours', 'minutes', 'seconds']);

    return displayLargestTimeInterval(duration);
  }, [dataFreshnessResults]);

  const anyLoading = workbookLoading || tokenLoading;
  const dashboardLoading = !contentUrl || tokenLoading || tableauVizLoading;

  return (
    <PageTemplate title="Dashboard">
      {isError && !anyLoading && (
        <p>Unable to load Dashboard. Please contact system admin.</p>
      )}
      {!isError && (
        <div style={{ paddingLeft: 32, paddingRight: 32 }}>
          <Helmet>
            <script type="text/javascript" src={`${customFontDomain}`} />
          </Helmet>
          <PageHeader
            title={workbook?.name || 'Workbook'}
            breadcrumbs={[
              { label: 'Insights+', to: '../../../..' },
              { label: workbook?.name || 'Workbook' },
            ]}
            actionsOverride={
              activeSheet &&
              vizRef?.current?.workbook && (
                <DownloadDashboardPdf
                  programId={programId}
                  dashboardId={dashboardId}
                  dashboardName={dashboardName ?? ''}
                />
              )
            }
            tabs={tabs}
            filterbar={
              <Box>
                {dashboardRefreshTimeInterval && (
                  <Flex end>
                    <span>Refreshed: {dashboardRefreshTimeInterval}</span>
                  </Flex>
                )}
                <Flex
                  start
                  style={{ visibility: activeSheet ? 'visible' : 'hidden' }}
                >
                  <DashboardFilterBar
                    dashboardId={dashboardId}
                    onParameterChanged={onParameterChanged}
                    onFilterChange={onFilterChange}
                  />
                </Flex>
              </Box>
            }
          />
          {(dashboardLoading || filtersLoading) && dashboardName && (
            <ShimmerLoading dashboardName={dashboardName} />
          )}

          <div
            className={cx(styles.tableauVizContainer, {
              [styles.hidden]: dashboardLoading || filtersLoading,
            })}
          >
            {contentUrl && !tokenLoading && (
              <TableauEmbed
                ref={vizRef}
                token={`${token}`}
                src={`${contentUrl}`}
                toolbar="hidden"
                hide-tabs
                iframe-auth
                onFirstInteractive={() => {
                  setTableauVizLoading(false);
                  setActiveSheet(vizRef.current?.workbook.activeSheet);
                }}
                onTabSwitched={handleTabSwitched}
              />
            )}
          </div>
          {dashboardId === '14c332a5-37e1-4871-903d-80e29ff065e5' && (
            <div>
              <UserActivityStreamCard
                campaignId={parseInt(filters.content_id?.[0], 10) ?? 1}
                columns={[
                  { key: 'user_name', header: 'Name', width: '15%' },
                  { key: 'email', header: 'Email', width: '15%' },
                  { key: 'channel', header: 'Channel', width: '10%' },
                  {
                    key: 'last_previewed',
                    header: 'Last Previewed',
                    width: '15%',
                    formatCell: formatDate,
                  },
                  {
                    key: 'last_opened',
                    header: 'Last Opened',
                    width: '15%',
                    formatCell: formatDate,
                  },
                  {
                    key: 'last_engaged',
                    header: 'Last Engaged',
                    width: '15%',
                    formatCell: formatDate,
                  },
                  {
                    key: 'last_activity',
                    header: 'Last Activity',
                    width: '15%',
                    formatCell: formatDate,
                  },
                ]}
                hasSearch
              />
            </div>
          )}
        </div>
      )}
    </PageTemplate>
  );
};
