import { RouteComponentProps } from '@reach/router';
import { AuthorCombobox } from 'components/channel-selection/inputs';
import { useDesign } from 'contexts/design';
import { Text } from 'DesignSystem/Typography';
import { useFieldVariables } from 'hooks/design/useFieldVariables';
import styles from './styles.module.css';

export function SettingsRoute(_props: RouteComponentProps): JSX.Element {
  const designContext = useDesign();
  const {
    author_name: defaultAuthorName,
    author_avatar: defaultAuthorAvatar,
  } = useFieldVariables();

  const {
    author_name: designAuthorName,
    author_avatar: designAuthorAvatar,
  } = designContext.design.meta;
  const designAuthor = {
    // if we have a name, use the provided avatar – even if that is null
    // else, we're going to default to the field variables
    avatarUrl: designAuthorName ? designAuthorAvatar : defaultAuthorAvatar,
    displayName: designAuthorName ?? defaultAuthorName,
  };

  return (
    <Container>
      <Text as="h2" className={{ PageHeading: true }}>
        Settings
      </Text>

      <div className={styles.roundedContainer}>
        <AuthorCombobox
          author={designAuthor}
          onAuthorSelect={(author) => {
            designContext.update({
              ...designContext.design,
              meta: {
                ...designContext.design.meta,
                author_avatar: author.avatarUrl,
                author_name: author.displayName,
              },
            });
          }}
        />
      </div>
    </Container>
  );
}

function Container({ children }: { children: React.ReactNode }) {
  return <section className={styles.content}>{children}</section>;
}
