import { Button } from '@socialchorus/shared-ui-components';
import React, { useCallback, useState } from 'react';
import { useProgram } from 'contexts/program';
import { ConfirmModal } from 'DesignSystem/Components';
import { FormSubsection, Section } from 'DesignSystem/Form';
import { Body, color } from 'DesignSystem/Typography';
import { clearBoxMappings } from 'services/api-box-mangement';
import styles from './styles.module.css';

export const BoxAdministrativeActions: React.FC = () => {
  const { id: programId } = useProgram();

  const [shouldClearFolderMappings, setShouldClearFolderMappings] = useState(
    false
  );
  const [isClearingFolderMappings, setIsClearingFolderMappings] = useState(
    false
  );

  const [shouldClearAllMappings, setShouldClearAllMappings] = useState(false);
  const [isClearingAllMappings, setIsClearingAllMappings] = useState(false);

  const onClearFolderMappings = useCallback(async () => {
    setShouldClearFolderMappings(false);
    setIsClearingFolderMappings(true);

    try {
      await clearBoxMappings(programId, false);
    } finally {
      setIsClearingFolderMappings(false);
    }
  }, [programId]);

  const onClearAllMappings = useCallback(async () => {
    setShouldClearAllMappings(false);
    setIsClearingAllMappings(true);

    try {
      await clearBoxMappings(programId, true);
    } finally {
      setIsClearingAllMappings(false);
    }
  }, [programId]);

  const actionInProgress = isClearingFolderMappings || isClearingAllMappings;

  return (
    <>
      <Section title="Administrative Actions">
        <FormSubsection title="Clear Folder Mappings" divider={false}>
          <div className={styles.AdministrativeAction}>
            <Body color={color.gray60}>
              References to Box files and folders in existing content and mapped
              folders may be unreachable if you move the root folder, or connect
              the integration to a different Box app.
              <br />
              Use this to clear all references to Box files and folders in the
              program.
            </Body>
            <Button
              variant="danger"
              label="Clear Folder Mappings"
              isLoading={isClearingFolderMappings}
              disabled={actionInProgress}
              onClick={() => setShouldClearFolderMappings(true)}
            />
          </div>
        </FormSubsection>
        <FormSubsection title="Clear All Mappings" divider={false}>
          <div className={styles.AdministrativeAction}>
            <Body color={color.gray60}>
              References to Box entities may be unreachable if you connect the
              integration to a different Box app.
              <br />
              Use this to clear all references to Box entities (folder mappings,
              user mappings, etc.) in the program.
            </Body>
            <Button
              variant="danger"
              label="Clear All Mappings"
              isLoading={isClearingAllMappings}
              disabled={actionInProgress}
              onClick={() => setShouldClearAllMappings(true)}
            />
          </div>
        </FormSubsection>
      </Section>

      {shouldClearFolderMappings && (
        <ConfirmModal
          title="Clear Folder Mappings?"
          confirmLabel="Clear mappings"
          onCancel={() => setShouldClearFolderMappings(false)}
          onConfirm={onClearFolderMappings}
        >
          <p>
            You are about to clear all references to the Box files and folders
            in the current program. This action cannot be undone.
          </p>
          <br />
          <p>
            Normally, this action is only needed when you move the root folder
            that causes the existing folder mappings to be unreachable. Are you
            sure you want to continue with clearing the mappings?
          </p>
        </ConfirmModal>
      )}

      {shouldClearAllMappings && (
        <ConfirmModal
          title="Clear All Mappings?"
          confirmLabel="Clear mappings"
          onCancel={() => setShouldClearAllMappings(false)}
          onConfirm={onClearAllMappings}
        >
          <p>
            You are about to clear all references to the Box files, folders and
            app users in the current program. This action cannot be undone.
          </p>
          <br />
          <p>
            Unless you are switching to a new Box app, you shouldn&apos;t need
            to perform this action. Are you sure you want to continue with
            clearing the mappings?
          </p>
        </ConfirmModal>
      )}
    </>
  );
};
