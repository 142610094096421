import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Subheading } from 'DesignSystem/Typography';
import { Flex } from 'DesignSystem/Layout/Flex';
import { CommunicationStep, Step } from 'models/journeys/journey';
import { useJourneyState } from 'contexts/journeys/journey';
import { useJourneyStepMetrics } from 'hooks/journeys/journey-metrics';
import styles from './step-reporting.module.css';
import { MetricItem } from './MetricItem';

export const StepReporting: React.FC<{
  step: Step;
}> = ({ step }) => {
  const { journey, currentGraph } = useJourneyState();

  if (!journey?.id || !currentGraph?.id || !currentGraph.isLive) return null;

  return (
    <>
      {step.type === 'communication' && (
        <EngagementReporting
          journeyId={journey.id}
          graphId={currentGraph.id}
          step={step}
        />
      )}
    </>
  );
};

const EngagementReporting: React.FC<{
  journeyId: number;
  graphId: number;
  step: CommunicationStep;
}> = ({ journeyId, graphId, step }) => {
  const { data } = useJourneyStepMetrics(
    journeyId,
    graphId,
    step.type,
    step.id
  );
  if (data === undefined) return null;

  const { sent, opened, clicked, delivered } = data;
  return (
    <Box padding={[0, 0, 48, 0]}>
      <Box margin={[0, 0, 24, 0]}>
        <Subheading bold>Engagement Metrics</Subheading>
      </Box>
      <Flex className={styles.metricItems}>
        <MetricItem key="sent" label="Sent" value={sent} />
        <MetricItem key="delivered" label="Delivered" value={delivered} />
        <MetricItem key="opens" label="Opens" value={opened} />
        <MetricItem key="clicks" label="Clicks" value={clicked} />
      </Flex>
    </Box>
  );
};
