import React from 'react';
import {
  DashboardFilter,
  useDashboardFilters,
} from '../hooks/useDashboardFilters';
import { DateRangePickerContainer } from './DashboardParameter';

export const DashboardFilterBar: React.FC<{
  dashboardId: string;
  onParameterChanged: (
    name: string,
    value: string | number | boolean | Date
  ) => void;
  onFilterChange: (name: string, values: string[]) => void;
}> = ({ dashboardId, onParameterChanged, onFilterChange }) => {
  const { data: dashboardFilters, isLoading } = useDashboardFilters(
    dashboardId
  );
  if (isLoading) return null;
  if (!dashboardFilters) return null;
  const renderFilters = () => {
    if (!dashboardFilters.filters.length) return null;
    return dashboardFilters.filters.map((x) => (
      <DashboardFilter key={x} filter={x} onChange={onFilterChange} />
    ));
  };
  const renderParameters = () => {
    if (!dashboardFilters.parameters.length) return null;
    return dashboardFilters.parameters.map(() => (
      <DateRangePickerContainer onChange={onParameterChanged} />
    ));
  };

  return (
    <div className="kai-flex-row">
      {renderParameters()}
      {renderFilters()}
    </div>
  );
};
