import React from 'react';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { DeleteTrash, Plus } from 'shared/icons';
import { useToggle } from 'hooks/useToggle';
import { background, Body, border, Text } from 'DesignSystem/Typography';
import { ChannelSelection } from 'components/channel-selection/channel-selection';
import { Notification, useNotificationValidator } from 'models/notification';
import classNames from 'classnames';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { selectedChannels } from 'models/channel';
import { SaveModalButtons } from '../../../Shared/SaveModalButtons';
import { NotificationCard } from '../../../Notifications/NotificationCard';
import { InfoTooltip } from '../../../Shared/InfoTooltip';
import {
  ContentTitle,
  Divisor,
  SettingsCardLayout,
  SettingsCardLayoutProps,
  SettingsEditButton,
} from '../../../SettingsCard';
import { useNotifications } from '../../../Notifications/useNotifications';
import { useEditableNotifications } from './hooks';

export function RetargetingNotificationCenter({
  disabled,
}: {
  disabled: boolean;
}): JSX.Element | null {
  const { reTargetingNotifications } = useNotifications();
  const hasRetargets = reTargetingNotifications.length > 0;
  const {
    settings: { deliveryChannels },
  } = useSettings();
  const { value: isEditing, toggle: toggleIsEditing } = useToggle();

  const {
    email: emailChannel,
    push: pushChannel,
    assistant: notificationCenterChannel,
  } = deliveryChannels;

  const hasNoChannels =
    !emailChannel && !pushChannel && !notificationCenterChannel;

  if (hasNoChannels) {
    return null;
  }

  if (isEditing) {
    return (
      <RetargetingLayout mode="edit">
        <Box margin={[-15, 0, 16, 0]}>
          <Body>
            Add retargeting notifications to send different messages per attempt
            to users who have not engaged.
          </Body>
        </Box>
        <EditableNotificationList
          disabled={disabled}
          cancelEditing={toggleIsEditing}
        />
      </RetargetingLayout>
    );
  }

  return (
    <RetargetingLayout mode="view">
      <ul>
        {reTargetingNotifications.map((notification) => {
          const notificationPosition = `${notification.order - 1}`;
          const notificationCardLabel = `${notificationPosition}__label`;
          return (
            <li
              key={notificationPosition}
              className={classNames(
                border.solid,
                border.width1,
                border.radius8,
                border.gray10,
                background.gray00
              )}
              style={{
                padding: '12px',
                margin: '0px 0px 12px 0px',
              }}
              aria-labelledby={notificationCardLabel}
            >
              <NotificationCard
                notification={notification}
                cardTitle={
                  <Text
                    className={{
                      Body: true,
                    }}
                    id={notificationCardLabel}
                  >
                    {`Retargeting Notification #${notificationPosition}`}
                  </Text>
                }
                disableEmail={!emailChannel}
                disablePush={!pushChannel}
                disableAssistant={!notificationCenterChannel}
              />
            </li>
          );
        })}
      </ul>

      {reTargetingNotifications.length === 0 && !isEditing && (
        <Box>
          <Button
            disabled={disabled}
            compact
            onClick={() => toggleIsEditing()}
            label={
              <>
                <Plus />
                <Box padding={[0, 12]}>Add retargeting notifications</Box>
              </>
            }
            secondary
          />
        </Box>
      )}

      {hasRetargets ? (
        <SettingsEditButton onClick={toggleIsEditing} disabled={disabled} />
      ) : null}
    </RetargetingLayout>
  );
}

function EditableNotificationList({
  disabled,
  cancelEditing,
}: {
  disabled: boolean;
  cancelEditing: () => void;
}) {
  const [
    notifications,
    {
      addNotification,
      deleteNotification,
      updateNotification,
      saveNotifications,
      isFormDirty,
      isNotificationsValid,
    },
  ] = useEditableNotifications();
  const hasRetargets = notifications.length > 0;
  const {
    contentPermissions: { restrictedFields },
  } = useSettings();

  const emailRestricted = restrictedFields.includes(
    RestrictedFields.SEND_EMAIL
  );
  const pushRestricted = restrictedFields.includes(RestrictedFields.SEND_PUSH);
  const notificationCenterRestricted = restrictedFields.includes(
    RestrictedFields.SEND_TO_ASSISTANT
  );
  const restrictedFromAllChannels =
    emailRestricted && pushRestricted && notificationCenterRestricted;
  const isUnableToAdd =
    disabled || !isNotificationsValid || restrictedFromAllChannels;
  const unmetSaveConditions = isUnableToAdd || !isFormDirty;
  return (
    <>
      <ul style={{ gap: 24, display: 'grid' }}>
        {notifications.map((notification, idx) => {
          const notificationPosition = idx + 1;
          const titleLabelId = `${notificationPosition}__label`;
          return (
            <li
              key={notificationPosition}
              className={classNames(
                border.solid,
                border.width1,
                border.radius8,
                border.gray10,
                background.gray00
              )}
              style={{ padding: '28px 24px' }}
              aria-labelledby={titleLabelId}
            >
              <RetargetNotificationForm
                titleId={titleLabelId}
                notification={notification}
                onUpdateNotification={(notif) => updateNotification(notif, idx)}
                onDeleteNotification={() => deleteNotification(idx)}
                disabled={disabled || restrictedFromAllChannels}
                position={notificationPosition}
              />
            </li>
          );
        })}
      </ul>
      <Box padding={[16, 0, 0, 0]} margin={hasRetargets ? 0 : [20, 0, 0, 0]}>
        <Button
          disabled={isUnableToAdd}
          compact
          onClick={addNotification}
          label={
            <>
              <Plus />
              <Box padding={[0, 12]}>
                {hasRetargets ? 'Add Another' : 'Add Notification'}
              </Box>
            </>
          }
          secondary
        />
      </Box>
      <SaveModalButtons
        onCancel={cancelEditing}
        onSave={() => {
          saveNotifications();
          cancelEditing();
        }}
        disabled={unmetSaveConditions}
      />
    </>
  );
}

function RetargetNotificationForm({
  titleId,
  notification,
  onUpdateNotification,
  onDeleteNotification,
  disabled,
  position,
}: {
  titleId: string;
  notification: Notification;
  onUpdateNotification: (notification: Partial<Notification>) => void;
  onDeleteNotification: () => void;
  disabled: boolean;
  position: number;
}) {
  const {
    settings: { deliveryChannels },
    contentPermissions: { restrictedFields },
  } = useSettings();

  const emailRestricted = restrictedFields.includes(
    RestrictedFields.SEND_EMAIL
  );
  const pushRestricted = restrictedFields.includes(RestrictedFields.SEND_PUSH);
  const notificationCenterRestricted = restrictedFields.includes(
    RestrictedFields.SEND_TO_ASSISTANT
  );

  const notificationValidity = useNotificationValidator(
    notification,
    selectedChannels(deliveryChannels)
  );
  const errors = !notificationValidity.validNotification;
  const {
    email: hasEmailChannel,
    push: hasPushChannel,
    assistant: hasNotificationCenterChannel,
  } = deliveryChannels;

  const notificationLabel = `Retargeting Notification #${position}`;

  return (
    <ChannelSelection
      title={
        <Text
          className={{
            Subheading: true,
            Semibold: true,
          }}
          id={titleId}
        >
          {notificationLabel}
        </Text>
      }
      display="persistent"
      actions={
        <Button
          compact
          borderless
          onClick={onDeleteNotification}
          label={
            <DeleteTrash
              aria-label={`delete ${notificationLabel}`}
              width={13}
              height={16}
            />
          }
          secondary
          disabled={disabled}
        />
      }
    >
      {hasEmailChannel && (
        <ChannelSelection.Email
          disabled={emailRestricted}
          subject={notification.text}
          onSubjectChange={(subject) => {
            onUpdateNotification({ ...notification, text: subject });
          }}
          previewText={notification.previewText}
          onPreviewTextChange={(preview) =>
            onUpdateNotification({ ...notification, previewText: preview })
          }
          errors={
            errors
              ? {
                  emailAlias: [],
                  subject: !notificationValidity.text ? ['Subject error'] : [],
                  previewText: !notificationValidity.previewText
                    ? ['Preview Text error']
                    : [],
                }
              : undefined
          }
        />
      )}
      {hasNotificationCenterChannel && (
        <ChannelSelection.NotificationCenter
          disabled={notificationCenterRestricted}
          title={notification.notificationCenterText ?? ''}
          onTitleChange={(title) =>
            onUpdateNotification({
              ...notification,
              notificationCenterText: title,
            })
          }
          markAsImportant={Boolean(
            notification.notificationCenterMarkAsImportant
          )}
          onMarkAsImportantChange={(markAsImportant) =>
            onUpdateNotification({
              ...notification,
              notificationCenterMarkAsImportant: markAsImportant,
            })
          }
        />
      )}
      {hasPushChannel && (
        <ChannelSelection.PushNotification
          disabled={pushRestricted}
          pushMessage={notification.pushText ?? ''}
          onPushMessageChange={(pushMessage) =>
            onUpdateNotification({ ...notification, pushText: pushMessage })
          }
        />
      )}
    </ChannelSelection>
  );
}

function RetargetingLayout({ children, ...props }: SettingsCardLayoutProps) {
  return (
    <>
      <Divisor />
      <SettingsCardLayout {...props}>
        <div>
          <div>
            <ContentTitle>
              Retargeting Notifications
              <InfoTooltip
                message={
                  <>
                    <div>
                      Retargeting notification text will be used in the order
                      configured. If the orchestration engine decides to make
                      additional deliveries of the campaign, the last configured
                      text will be used for those retargeting attempts.
                    </div>
                    <Box margin={['1em', 0, 0, 0]}>
                      Note: If no text configuration is made here, the engine
                      will leverage the initial subject line/push text for
                      future deliveries.
                    </Box>
                  </>
                }
              />
            </ContentTitle>
          </div>
          {children}
        </div>
      </SettingsCardLayout>
    </>
  );
}
