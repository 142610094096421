import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Section, Subsection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Body, color } from 'DesignSystem/Typography';
import { BoxConfigurationStatus } from 'services/api-box-mangement';
import { useBoxConfigurationStatus } from 'shared/Box/useBoxConfigurationStatus';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { BOX_CONFIGURATION_PAGE_BREADCRUMBS } from '../shared/breadcrumbs';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from '../shared/header';
import { useBoxConfigurationPageTabs } from '../shared/useBoxConfigurationPageTabs';
import { BoxAdministrativeActions } from './BoxAdministrativeActions';
import styles from './styles.module.css';

export const BoxConfigurationOverview: React.FC<RouteComponentProps> = () => {
  const tabs = useBoxConfigurationPageTabs();

  const {
    data: boxConfigurationStatus,
    isFetching,
  } = useBoxConfigurationStatus();

  return (
    <FormPage
      title={BOX_CONFIGURATION_PAGE_TITLE}
      description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
      tabs={tabs}
      breadcrumbs={BOX_CONFIGURATION_PAGE_BREADCRUMBS}
    >
      <div className={styles.SectionsContainer}>
        <Section title="App Configuration" href="./credentials">
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <Body color={color.gray60}>
              {boxConfigurationStatus?.configured ? (
                <>
                  App was configured with client ID:{' '}
                  <strong>{boxConfigurationStatus.clientId}</strong> on{' '}
                  <strong>
                    {DateTime.fromJSDate(
                      new Date(boxConfigurationStatus.credentialsUpdatedAt)
                    ).toFormat('MMM dd, yyyy')}
                  </strong>
                </>
              ) : (
                'App has not been configured'
              )}
            </Body>
          )}
        </Section>
        {boxConfigurationStatus?.configured && (
          <>
            <RootFolderSection
              boxConfigurationStatus={boxConfigurationStatus}
              isFetching={isFetching}
            />
            <BoxAdministrativeActions />
          </>
        )}
      </div>
    </FormPage>
  );
};

const RootFolderSection: React.FC<{
  boxConfigurationStatus?: BoxConfigurationStatus;
  isFetching: boolean;
}> = ({ boxConfigurationStatus, isFetching }) => {
  const Content = useMemo(() => {
    if (!boxConfigurationStatus?.configured) return null;

    if (boxConfigurationStatus.rootFolderId === 0) {
      return (
        <Body color={color.gray60}>
          <>
            <strong>
              Currently configured to default folder (top level folder)
            </strong>
          </>
        </Body>
      );
    }

    return (
      <Subsection title="Root Folder">
        <Body>{boxConfigurationStatus.rootFolderName}</Body>
      </Subsection>
    );
  }, [boxConfigurationStatus]);

  if (!boxConfigurationStatus?.configured) return null;

  return (
    <Section title="Root Folder Selection" href="./root-folder">
      {isFetching ? <LoadingSpinner /> : Content}
    </Section>
  );
};
