import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useProgram } from 'contexts/program';
import {
  BoxConfigurationStatus,
  getBoxConfigurationStatus,
} from 'services/api-box-mangement';

type UseBoxConfigurationStatusOptions = UseQueryOptions<BoxConfigurationStatus>;
type UseBoxConfigurationStatusResult = UseQueryResult<BoxConfigurationStatus>;

export const getBoxConfigurationStatusQueryKey = (
  programId: number
): QueryKey => ['box-km-configuration', programId];

export function useBoxConfigurationStatus(
  options?: UseBoxConfigurationStatusOptions
): UseBoxConfigurationStatusResult {
  const { id: programId } = useProgram();

  return useQuery(
    getBoxConfigurationStatusQueryKey(programId),
    () => getBoxConfigurationStatus(programId),
    options
  );
}
