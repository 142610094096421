import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import { WithPermission } from 'shared/WithPermission';
import { BoxManageFoldersList } from './BoxManageFoldersList';
import { NewBoxFolder } from './create';
import { EditBoxFolder } from './edit';
import { BoxManageContent } from './manage';
import {
  BoxConfigurationOverview,
  BoxConfigurationPage,
  BoxConfigureAppCredentials,
  BoxConfigureRootFolder,
} from './configure';

export const BoxKnowledgeManagement: React.FC<RouteComponentProps> = () => {
  return (
    <WithPermission
      permissions={['configureBoxKnowledgeManagementAccess']}
      operation="all"
    >
      <Router>
        <BoxManageFoldersList path="manage-folders" />
        <NewBoxFolder path="manage-folders/folders/new" />
        <EditBoxFolder path="manage-folders/folders/:id/edit" />

        <BoxManageContent path="manage-content" />

        <BoxConfigurationPage path="settings">
          <BoxConfigurationOverview path="/" />
          <BoxConfigureAppCredentials path="credentials" />
          <BoxConfigureRootFolder path="root-folder" />
        </BoxConfigurationPage>

        <Redirect from="*" to="manage-folders" noThrow />
      </Router>
    </WithPermission>
  );
};
