import {
  BoxContentExplorer,
  BoxItem,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import { RouteComponentProps, useLocation } from '@reach/router';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { PageTemplate } from 'DesignSystem/Layout/Pages';
import { filterBoxResponseByPermission } from 'services/api-box-mangement';
import { useBoxRootFolder } from 'shared/Box/useBoxRootFolder';
import { useBoxToken } from 'shared/Box/useBoxToken';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { BoxConfigurationPageHeader } from '../shared/header';
import styles from './styles.module.css';

export const BoxManageContent: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();

  const {
    data: rootFolderData,
    isLoading: isLoadingConfiguration,
  } = useBoxRootFolder();

  const rootFolderId = rootFolderData?.rootFolderId.toString();

  const { token, isLoading: isLoadingToken, refetch } = useBoxToken({
    resourceType: 'folder',
    resourceId: rootFolderId,
  });

  const [canCreateFolder, setCanCreateFolder] = useState(
    permissions.boxManageAllContentAccess
  );
  const [canUpload, setCanUpload] = useState(
    permissions.boxManageAllContentAccess
  );

  const onNavigate = useCallback(
    (item: BoxItem) => {
      if (permissions.boxManageAllContentAccess) {
        setCanCreateFolder(true);
        setCanUpload(true);
      } else {
        const isAtRoot = item.type === 'folder' && item.id === rootFolderId;
        setCanCreateFolder(!isAtRoot);
        setCanUpload(!isAtRoot);
      }
    },
    [permissions.boxManageAllContentAccess, rootFolderId]
  );

  const permissionFilter = useCallback(
    (response: AxiosResponse<BoxItem>) =>
      filterBoxResponseByPermission(programId, response),
    [programId]
  );

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenResponse = await refetch();

      if (!tokenResponse.data?.token) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenResponse.data.token}`,
        },
      });
    },
    [refetch]
  );

  const responseInterceptor = useResponseInterceptor({
    permissionFilter: permissions.boxManageAllContentAccess
      ? undefined
      : permissionFilter,
    refetchToken,
  });

  const isLoading = isLoadingToken || isLoadingConfiguration;

  return (
    <PageTemplate title="Box Knowledge Management" noNavigation={false}>
      <div className={styles.ContentPage}>
        <BoxConfigurationPageHeader />
        {isLoading ? (
          <div className={styles.Loading}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={styles.ContentExplorerContainer}>
            <MemoryRouter initialEntries={[location]} initialIndex={0}>
              <BoxContentExplorer
                token={token}
                folderId={rootFolderId || '0'}
                responseInterceptor={responseInterceptor}
                canCreateNewFolder={canCreateFolder}
                canUpload={canUpload}
                onNavigate={onNavigate}
              />
            </MemoryRouter>
          </div>
        )}
      </div>
    </PageTemplate>
  );
};
