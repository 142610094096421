import { Flex } from 'DesignSystem/Layout/Flex';
import React from 'react';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { UpperBar, LowerBar } from 'shared/NavigationBars/FixedBars';
import { Body } from 'DesignSystem/Typography';
import { NavigationFooter } from 'shared/NavigationFooter';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import { Box, ConfirmModal } from 'DesignSystem/Components';
import { EditableTitle } from 'shared/NavigationBars/utils';
import { DesignError, useDesign } from 'contexts/design';
import { useFlashMessage } from 'contexts/flasher';
import { DesignData } from 'services/api-design';
import { NavLink } from 'shared/NavLink';
import { MAIcon } from 'shared/MAIcon';
import { useJourneyState } from 'contexts/journeys/journey';
import { SaveButton } from '../JourneyCanvasHeader/Buttons';
import styles from './styles.module.css';
import { useDesignPersistenceStatus } from './useDesignPersistenceStatus';
import { CloudStatus } from '../JourneyCanvasHeader/useJourneyPersistenceStatus';
import { JourneyModal } from '../JourneyModal';
import { useJourneyContentDesigner } from './JourneyContentDesignProvider';

type NavigationBarsProps = React.ComponentProps<typeof NavigationFooter> & {
  closeAndSetDesignerId: (designId?: number) => void;
};

enum SaveType {
  Save = 'Save',
  SaveAndClose = 'SaveAndClose',
}

export const NavigationBars: React.FC<NavigationBarsProps> = ({
  closeAndSetDesignerId,
  canPerformAction,
  isWorking,
  hideSave,
}) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = React.useState(false);
  const [
    isUnsavedChangesModalOpen,
    setIsUnsavedChangesModalOpen,
  ] = React.useState(false);
  const { onSelectDesign } = useJourneyContentDesigner();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const { cloudStatus } = useDesignPersistenceStatus();
  const designContext = useDesign();
  const { setFlashMessage } = useFlashMessage();
  const [designName, setDesignName] = React.useState(
    designContext.design.name || 'Untitled'
  );
  const { activeStep } = useJourneyState();
  const [isShowingReplaceModal, setIsShowingReplaceModal] = React.useState(
    false
  );

  const activeStepHasDesign = React.useMemo(() => {
    return (
      activeStep &&
      activeStep.type === 'communication' &&
      activeStep.designId !== undefined
    );
  }, [activeStep]);
  const [saveType, setSaveType] = React.useState<SaveType | undefined>();

  const isNameValid = React.useMemo(() => {
    return (
      designContext.design.name &&
      designContext.design.name !== 'Untitled' &&
      designContext.design.name.length > 0
    );
  }, [designContext.design.name]);

  const hideWarning = React.useCallback(() => {
    setIsUnsavedChangesModalOpen(false);
  }, [setIsUnsavedChangesModalOpen]);

  const showNameError = React.useCallback(() => {
    setErrorMessage(
      'Content name is required to save. Name your Content and try saving again.'
    );
    setIsErrorModalOpen(true);
    hideWarning();
  }, [hideWarning]);

  const dismissErrorModal = React.useCallback(() => {
    setIsErrorModalOpen(false);
    setErrorMessage(undefined);
  }, [setIsErrorModalOpen]);

  const showSuccessMessage = React.useCallback(() => {
    setFlashMessage({
      message: 'Communication updated',
      severity: 'info',
    });
  }, [setFlashMessage]);

  const onSaveError = React.useCallback((errors: Array<DesignError>) => {
    const hasNameError = errors.some((e: { detail?: string }) =>
      e.detail?.includes('Name has already been taken')
    );
    setIsErrorModalOpen(true);
    if (errors && hasNameError) {
      setErrorMessage(
        'The name of this content is already in use by another piece of content. Change the name of this content and try saving again.'
      );
    } else {
      setErrorMessage(
        'There was an issue saving this content. Please try again.'
      );
    }
  }, []);

  const onSaveSuccess = React.useCallback(
    (design?: DesignData) => {
      if (design?.attributes) {
        onSelectDesign(design.attributes);
        showSuccessMessage();
      }
    },
    [onSelectDesign, showSuccessMessage]
  );

  const handleSave = React.useCallback(
    (onSuccess?: (designId?: number) => void) => {
      designContext.save({
        onSuccess: (design?: DesignData) => {
          onSaveSuccess(design);
          if (onSuccess && design) onSuccess(Number(design.attributes.id));
        },
        onError: onSaveError,
      });
    },
    [designContext, onSaveError, onSaveSuccess]
  );

  const save = React.useCallback(() => {
    if (isNameValid) {
      handleSave();
    } else showNameError();
  }, [isNameValid, showNameError, handleSave]);

  const saveAndClose = React.useCallback(() => {
    if (isNameValid) {
      handleSave(closeAndSetDesignerId);
      setIsUnsavedChangesModalOpen(false);
    } else showNameError();
  }, [isNameValid, showNameError, handleSave, closeAndSetDesignerId]);

  const updateDesignName = React.useCallback(
    (name: string) => {
      setDesignName(name);
      designContext.update({ ...designContext.design, name });
    },
    [designContext]
  );

  const handleCloseWithoutSaving = React.useCallback(() => {
    if (
      !isUnsavedChangesModalOpen &&
      cloudStatus !== CloudStatus.UpToDate &&
      !hideSave
    )
      setIsUnsavedChangesModalOpen(true);
    else closeAndSetDesignerId(undefined);
  }, [isUnsavedChangesModalOpen, cloudStatus, hideSave, closeAndSetDesignerId]);

  const onSaveButtonClick = React.useCallback(() => {
    if (designContext.design.id === 'new' && activeStepHasDesign) {
      setIsShowingReplaceModal(true);
      setSaveType(SaveType.Save);
    } else {
      save();
    }
  }, [activeStepHasDesign, designContext.design.id, save]);

  const onSaveAndCloseButtonClick = React.useCallback(() => {
    if (designContext.design.id === 'new' && activeStepHasDesign) {
      setIsShowingReplaceModal(true);
      setSaveType(SaveType.SaveAndClose);
    } else {
      saveAndClose();
    }
  }, [activeStepHasDesign, designContext.design.id, saveAndClose]);

  const closeReplaceModal = React.useCallback(() => {
    setIsShowingReplaceModal(false);
    setSaveType(undefined);
  }, [setIsShowingReplaceModal]);

  const handleReplacementConfirmation = React.useCallback(() => {
    if (saveType === SaveType.Save) {
      save();
    } else if (saveType === SaveType.SaveAndClose) {
      saveAndClose();
    }
    closeReplaceModal();
  }, [closeReplaceModal, save, saveAndClose, saveType]);

  return (
    <>
      <UpperBar>
        <Flex>
          <Text.Subheading color={Text.color.gray40}>
            Create Content
          </Text.Subheading>
          <Box color={Text.color.gray40} padding={[0, 8, 0, 12]}>
            /
          </Box>
          <EditableTitle
            title={designName}
            updateTitle={updateDesignName}
            maxLength={100}
            defaultTitle="Untitled"
          />
        </Flex>
        <Flex end>
          {!!canPerformAction && !hideSave && (
            <SaveButton
              isSaving={!!isWorking}
              status={cloudStatus}
              handleSave={onSaveButtonClick}
            />
          )}
          <CloseButton handleClose={handleCloseWithoutSaving} />
        </Flex>
      </UpperBar>
      {isShowingReplaceModal && (
        <ConfirmModal
          width={431}
          onConfirm={handleReplacementConfirmation}
          confirmLabel="Replace"
          title="Replace Content?"
          onCancel={closeReplaceModal}
          titleIcon={
            <MAIcon name="warning" className={Text.color.orangeFull} />
          }
        >
          <Body color={Text.color.gray60}>
            This will replace the communication content. Current content is not
            recoverable and the action cannot be undone.
          </Body>
        </ConfirmModal>
      )}
      {isErrorModalOpen && errorMessage && (
        <JourneyModal
          name="Save error"
          header="Save error"
          body={errorMessage}
          action={dismissErrorModal}
          actionLabel="OK"
        />
      )}
      {isUnsavedChangesModalOpen && (
        <ConfirmModal
          onConfirm={onSaveAndCloseButtonClick}
          confirmLabel="Save changes"
          cancelLabel="Don't save"
          title="Unsaved changes"
          onDismiss={hideWarning}
          onCancel={() => closeAndSetDesignerId(undefined)}
        >
          <Body color={Text.color.gray60}>
            You have unsaved changes to this content. If you close without
            saving, your changes will be lost. Would you like to save your
            changes before closing the editor?
          </Body>
        </ConfirmModal>
      )}
      <LowerBar>
        <Flex className={styles.footer} end spread>
          <Flex>
            <NavLink to="./" shouldDisablePartialClass>
              Design
            </NavLink>
            <NavLink to="./settings" shouldDisablePartialClass>
              Settings
            </NavLink>
          </Flex>
          <Button
            label="Save and Close"
            onClick={onSaveAndCloseButtonClick}
            disabled={!canPerformAction}
          />
        </Flex>
      </LowerBar>
    </>
  );
};
