import React from 'react';
import cx from 'classnames';
import { Flex, Space } from 'DesignSystem/Layout/Flex';
import { DateTime } from 'luxon';
import { Comment } from 'services/api-content';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Button } from 'shared/Button';

import styles from './comments-modal.module.css';

type CommentProps = {
  contentId: number;
  programId: number;
  comment: Comment;
  isReply?: boolean;
  deleteFlags?: (data: {
    programId: number;
    contentId: number;
    commentId: string;
    comment: Comment;
  }) => void;
  deleteComment: (data: {
    programId: number;
    contentId: number;
    commentId: string;
  }) => void;
  isDeleting?: boolean;
  deleteId?: string | number | null;
  isDeletingFlag?: boolean;
  deferId?: string | number | null;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromISO(d) : undefined;
};

export const Comments: React.FC<CommentProps> = ({
  programId,
  comment,
  contentId,
  deleteFlags,
  deleteComment,
  isDeleting,
  deleteId,
  deferId,
  isDeletingFlag,
}) => {
  const {
    id,
    author,
    rawContent,
    isReported,
    replies,
    createdAt,
    deletedAt,
  } = comment;
  const dateCreated = formatDate(createdAt)?.toFormat('hh:mma MM/dd/yyyy');

  return (
    <div className={styles.commentBox}>
      <Flex
        start
        className={cx(styles.container, {
          [styles.reported]: isReported,
        })}
      >
        <div className={styles.avatarBox}>
          {author.avatar.url ? (
            <img
              src={author.avatar.url}
              alt="author"
              className={styles.avatar}
            />
          ) : (
            <Flex className={styles.fallbackAvatar}>
              {author.name[0]?.toUpperCase()}
            </Flex>
          )}
        </div>
        <div>
          <Flex start className={styles.commentHeader}>
            <p className={styles.authorName}>{author.name}</p>
            <p className={styles.createdAt}>{dateCreated}</p>
            {deletedAt && <span className={styles.deleted}>deleted</span>}
          </Flex>
          <p>{rawContent}</p>
          <Flex
            start
            className={cx(styles.actionButtons, {
              [styles.hide]: deletedAt,
            })}
          >
            <Button
              compact
              type="primary"
              onClick={() =>
                deleteComment({ programId, contentId, commentId: id })
              }
            >
              <Flex>
                {isDeleting && deleteId === id ? (
                  <Flex>
                    <LoadingSpinner size="small" />
                    <Space />
                  </Flex>
                ) : null}
                <p>{isDeleting && deleteId === id ? 'Deleting' : 'Delete'}</p>
              </Flex>
            </Button>
            <div className={styles.deleteButton}>
              {isReported ? (
                <Button
                  compact
                  type="primary"
                  onClick={() =>
                    deleteFlags &&
                    deleteFlags({
                      programId,
                      contentId,
                      commentId: id,
                      comment,
                    })
                  }
                >
                  <Flex>
                    {isDeletingFlag && deferId === id ? (
                      <Flex>
                        <LoadingSpinner size="small" />
                        <Space />
                      </Flex>
                    ) : null}
                    <p>Remove flag</p>
                  </Flex>
                </Button>
              ) : null}
            </div>
          </Flex>
        </div>
      </Flex>
      <div className={styles.repliesBox}>
        {replies && replies.length
          ? replies.map((reply) => (
              <Comments
                key={reply.id}
                contentId={contentId}
                programId={programId}
                comment={reply}
                isReply
                isDeleting={isDeleting}
                deleteId={deleteId}
                isDeletingFlag={isDeletingFlag}
                deferId={deferId}
                deleteFlags={deleteFlags}
                deleteComment={() =>
                  deleteComment({
                    programId,
                    contentId,
                    commentId: reply.id,
                  })
                }
              />
            ))
          : null}
      </div>
    </div>
  );
};
