import { Button } from 'DesignSystem/Form';
import { useJourneyState } from 'contexts/journeys/journey';
import React from 'react';
import { WarningTriangle } from 'shared/icons';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Step } from 'models/journeys/journey';
import { capitalizeFirstLetter } from 'utility/strings';
import { useReactFlow } from 'reactflow';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Box } from 'DesignSystem/Components';
import { ExpandContractPill } from 'DesignSystem/Components/ExpandContractPill';
import styles from './errors.module.css';
import { useJourneyValidationErrors } from './useJourneyValidationErrors';
import { JourneyModal } from '../JourneyModal';
import { center } from '../JourneyCanvas/utils/node';

type JourneyErrorsType = {
  showModal: boolean;
  closeModal: () => void;
};
export const JourneyErrors: React.FC<JourneyErrorsType> = ({
  showModal,
  closeModal,
}) => {
  const { setActiveStepId, currentGraph, isValidating } = useJourneyState();
  const { errors, errorsCount } = useJourneyValidationErrors();
  const { showErrorsList, setShowErrorsList } = useJourneyState();
  const flowInstance = useReactFlow();

  const selectStep = React.useCallback(
    (key) => {
      setActiveStepId(key);
      center(flowInstance, key);
    },
    [flowInstance, setActiveStepId]
  );

  const stepNames = React.useMemo(() => {
    const result: { [key: string]: string } = {};
    currentGraph?.steps.forEach((step: Step) => {
      result[step.id] = capitalizeFirstLetter(step.type);
    });
    result.initiation = 'Initiation';
    return result;
  }, [currentGraph?.steps]);

  const renderErrorDescription = React.useCallback(
    (key: string): string => {
      if (!errors?.graph?.[key]) return '';
      const errorMessages = Object.values(errors.graph[key]).flat();
      return errorMessages.length === 1
        ? errorMessages[0]
        : `${errorMessages.length} errors`;
    },
    [errors]
  );

  if (!errors?.graph || errorsCount === 0) return null;

  return (
    <>
      {!isValidating && (
        <ExpandContractPill
          buttonLabel={`${errorsCount} ${errorsCount > 1 ? 'errors' : 'error'}`}
          isOpen={showErrorsList}
          close={() => setShowErrorsList(false)}
          toggle={() => setShowErrorsList(!showErrorsList)}
        >
          <Flex column className={styles.list}>
            {Object.keys(errors.graph).map((key: string) => {
              return (
                <Button
                  key={key}
                  layoutOnly
                  onClick={() => {
                    selectStep(key);
                    setShowErrorsList(false);
                  }}
                  className={styles.listItem}
                  label={
                    <Flex className={styles.errorFlex}>
                      <div className={styles.iconContainer}>
                        <WarningTriangle />
                      </div>
                      <div
                        className={styles.errorText}
                        title={renderErrorDescription(key)}
                      >
                        {stepNames[key]} - {renderErrorDescription(key)}
                      </div>
                    </Flex>
                  }
                />
              );
            })}
          </Flex>
        </ExpandContractPill>
      )}
      {isValidating && (
        <Box padding={[5, 16, 0, 0]}>
          <LoadingSpinner size="small" />
        </Box>
      )}
      {showModal && (
        <JourneyModal
          name="Errors"
          action={closeModal}
          actionLabel="Ok"
          header="Errors"
          body={
            errorsCount === 1
              ? `There is 1 error that needs to be fixed before
          publishing this journey`
              : `There are ${errorsCount} errors that need to be fixed before
          publishing this journey`
          }
        />
      )}
    </>
  );
};
