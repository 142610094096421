import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import {
  useBoxFolderCreatorsQuery,
  useBoxFoldersInfiniteQuery,
} from 'hooks/box-knowledge-mangement';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useDebounce } from 'hooks/useDebounce';
import { BoxFolderCreator } from 'services/api-box-mangement';
import { OptionType } from 'hooks/common';
import { useGenericFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { BoxKMBanner } from './BoxFolderBanner';
import styles from './box.km.module.css';
import { BOX_CONFIGURATION_PAGE_BREADCRUMBS } from './shared/breadcrumbs';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from './shared/header';
import { BoxFiltersBar, FilterType, FilterValues } from './BoxFiltersBar';
import { useBoxConfigurationPageTabs } from './shared/useBoxConfigurationPageTabs';

const mapCreatorData = (creatorData: BoxFolderCreator[] = []): OptionType[] => {
  const sortedCreatorData = creatorData?.sort(({ id: aId }, { id: bId }) =>
    aId > bId ? 1 : -1
  );

  return (
    sortedCreatorData?.map(({ id, name }: { name: string; id: string }) => ({
      label: name || 'Internal User',
      value: id.toString(),
    })) || []
  );
};

export const BoxManageFoldersList: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const tabs = useBoxConfigurationPageTabs();

  const { data: creatorsApiData } = useBoxFolderCreatorsQuery(programId);

  const creatorOptions = React.useMemo(() => mapCreatorData(creatorsApiData), [
    creatorsApiData,
  ]);

  const [creatorSearchTerm, setCreatorSearchTerm] = React.useState('');

  const [
    selectedCreators,
    setSelectedCreators,
  ] = useGenericFilterDropdownQueryString<string[]>({ key: 'creators' });

  const filteredCreatorOptions = React.useMemo(() => {
    const truncateFilterItems = (
      needle: string,
      filters: OptionType[] = [],
      creators: string[] = []
    ) => {
      const filteredOptions = filters.filter(({ label }) =>
        label.toLowerCase().includes(needle.toLowerCase())
      );
      const selectedOptions = filters.filter((option) =>
        creators.includes(option.value)
      );

      const combinedOptions = [
        ...selectedOptions.filter(
          (option) =>
            !filteredOptions.some((filtered) => filtered.value === option.value)
        ),
        ...filteredOptions,
      ];

      return combinedOptions;
    };

    return truncateFilterItems(
      creatorSearchTerm,
      creatorOptions,
      selectedCreators
    );
  }, [creatorSearchTerm, creatorOptions, selectedCreators]);

  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const search = useDebounce(searchTerm);
  const creators = useDebounce(selectedCreators);

  const filterChangeCallbacks: {
    [K in FilterType]: (value: FilterValues[K]) => void;
  } = {
    [FilterType.SEARCH]: setSearchTerm,
    [FilterType.CREATOR]: setSelectedCreators,
    [FilterType.CREATOR_SEARCH]: setCreatorSearchTerm,
  };

  const onFilterChange = <K extends FilterType>(
    filterName: K,
    values: FilterValues[K]
  ) => {
    filterChangeCallbacks[filterName](values);
  };

  const onDropDownClose = () => {
    setCreatorSearchTerm('');
  };

  const infiniteQuery = useBoxFoldersInfiniteQuery({
    programId,
    q: search,
    creatorIds: creators,
  });

  return (
    <div className={styles.List}>
      <ListPage
        title={BOX_CONFIGURATION_PAGE_TITLE}
        description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
        tabs={tabs}
        breadcrumbs={BOX_CONFIGURATION_PAGE_BREADCRUMBS}
        infiniteQuery={infiniteQuery}
        loading={<></>}
        actions={[
          {
            icon: <Icon name="Plus" fill="currentColor" />,
            label: 'Folder',
            to: './folders/new',
          },
        ]}
        emptyList={
          <div>
            No folders found. When you create a folder, you will see it here.
          </div>
        }
        filterbar={
          <BoxFiltersBar
            onChange={onFilterChange}
            onClose={onDropDownClose}
            searchTerm={searchTerm}
            creators={filteredCreatorOptions}
            selectedCreators={selectedCreators}
            creatorSearchTerm={creatorSearchTerm}
          />
        }
        renderRow={(item, index) => (
          <BoxKMBanner boxFolderData={item} index={index} />
        )}
      />
    </div>
  );
};
