import * as React from 'react';
import cx from 'classnames';
import { Close } from 'shared/icons';
import styles from 'DesignSystem/Table/filters.module.css';

export const DashboardFilterTriggerButton: React.FC<{
  name: string;
  value: string[];
  onClose?: () => void;
}> = ({ name, value, onClose }) => {
  const classNames = React.useMemo(
    () => ({
      FilterTrigger: styles.FilterTrigger,
      FilterTriggerCloseable: styles.FilterTriggerCloseable,
      FilterTriggerApplied: styles.FilterTriggerApplied,
      FilterTriggerCloseButton: styles.FilterTriggerCloseButton,
      FontWeight400: styles.FontWeight400,
    }),
    []
  );

  const icon = (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.88916 1.7778L7.00027 6.22225L13.1114 1.7778"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );

  return (
    <div
      className={cx(classNames.FilterTrigger, {
        [classNames.FilterTriggerCloseable]: !!onClose,
        [classNames.FilterTriggerApplied]: !!onClose || value?.length > 0,
      })}
    >
      <span className={styles.FlexView}>
        {name}
        {`: ${value.join(', ')}`}
      </span>
      {onClose ? (
        <div>
          <button
            className={classNames.FilterTriggerCloseButton}
            onClick={onClose}
            type="button"
          >
            <Close />
          </button>
        </div>
      ) : (
        icon
      )}
    </div>
  );
};
