import { useProgram } from 'contexts/program';
import { useAudiencesQuery, useValueSuggestionsQuery } from 'hooks/audience';
import { useInitiativesQuery } from 'hooks/initiatives';
import { useTopicsQuery } from 'hooks/topics';
import { Option } from 'models/insight/json/filterJson';
import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { DashboardFilters, fetchDashboardFilters } from 'services/api-insights';
import { useContentQuery } from '../../../../../hooks/content';
import { editableTextToQuery } from '../../Journey/JourneyDrawer/shared/AudienceSelect';
import { DashboardFilterDropdown } from '../components/DashboardFilterDropdown';
import { generateDateValues } from '../components/filter-utils';

type DashboardFilterProps = {
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
};

const DashboardFilterWrapper: React.FC<
  DashboardFilterProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryHook: any;
    filterKey: string;
    label: string;
    valueTransform?: (data: {
      [key: string]: string;
    }) => {
      label: string;
      value: string;
    };
    multiSelect?: boolean;
  }
> = ({
  onChange,
  queryHook,
  filterKey,
  label,
  valueTransform = (data) => ({
    label: data?.name,
    value: data?.name,
  }),
  multiSelect = true,
}) => {
  const programId = useProgram().id;
  const { data, isLoading } = queryHook({ programId });
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  const onFilterChange = React.useCallback(
    (values: string[]) => {
      if (multiSelect) {
        setSelectedValues(values);
        onChange(filterKey, values);
      } else {
        setSelectedValues([values[values.length - 1]]);
        onChange(filterKey, [values[values.length - 1]]);
      }
    },
    [onChange, filterKey, multiSelect]
  );

  if (isLoading) return <></>;
  if (data?.length) {
    const options = data.map(valueTransform);
    return (
      <DashboardFilterDropdown
        onChange={onFilterChange}
        options={options}
        label={label}
        multiSelect={multiSelect}
        selectedOptions={options.filter((opt: Option) => {
          if (multiSelect) {
            return selectedValues?.includes(opt.value);
          }
          return selectedValues[0] === opt.value;
        })}
      />
    );
  }
  return <></>;
};

export const DashboardFilter: React.FC<{
  filter: string;
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
}> = ({ filter, onChange }) => {
  const useUserDepartmentQuery = ({ programId }: { programId: number }) => {
    const response = useValueSuggestionsQuery(
      programId,
      'department',
      '*',
      editableTextToQuery('*')
    );
    return {
      data: response.data,
      isLoading: response.isLoading,
    };
  };

  const dashboardFilters: Record<
    string,
    {
      queryHook: unknown;
      filterKey: string;
      label: string;
      valueTransform?: (data: {
        [key: string]: string;
      }) => {
        label: string;
        value: string;
      };
      multiSelect?: boolean;
    }
  > = {
    content: {
      queryHook: useContentQuery,
      filterKey: 'content_id',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: `${data.id} ${data.title}`,
          value: data.id.toString(),
        };
      },
      label: 'Content',
    },
    topics: {
      queryHook: useTopicsQuery,
      filterKey: 'topic_id',
      label: 'Topics',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
    },
    initiatives: {
      queryHook: useInitiativesQuery,
      filterKey: 'initiative_tag_id',
      label: 'Initiative',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
    },
    audiences: {
      queryHook: useAudiencesQuery,
      filterKey: 'audience_id',
      label: 'Audiences',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.title,
          value: data.id.toString(),
        };
      },
    },
    timeRange: {
      queryHook: () => {
        return {
          data: generateDateValues('month'),
          isLoading: false,
        };
      },
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.label,
          value: data.value,
        };
      },
      filterKey: 'month_year_filterable',
      label: 'Time Range',
    },
    userDepartment: {
      queryHook: useUserDepartmentQuery,
      filterKey: 'attribute_department',
      label: 'User Department',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.value,
          value: data.value,
        };
      },
    },
  };

  if (!dashboardFilters[filter]) return <></>;

  return (
    <DashboardFilterWrapper onChange={onChange} {...dashboardFilters[filter]} />
  );
};

type DashboardFilterObj = {
  filters: string[];
  parameters: string[];
};

const transformDashboardFilters = (
  filters: DashboardFilters[]
): DashboardFilterObj => {
  const parameter_keys: string[] = [];
  const filter_keys: string[] = [];
  filters.forEach((filter) => {
    if (filter.filter_type === 'parameter') {
      parameter_keys.push(filter.name);
    } else {
      filter_keys.push(filter.name);
    }
  });
  return {
    filters: filter_keys,
    parameters: parameter_keys,
  };
};

export const useDashboardFilters = (
  dashboardId: string
): UseQueryResult<DashboardFilterObj> => {
  const programId = useProgram().id;
  return useQuery({
    queryKey: ['dashboardFilters', dashboardId],
    queryFn: () =>
      fetchDashboardFilters({ programId, dashboardId }).then((res) => {
        return transformDashboardFilters(res.data);
      }),
    staleTime: 2 * 60,
  });
};
