import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';
import camelcaseKeys from 'camelcase-keys';
import { ContentPageCursor } from 'models/content-page';
import { Topic } from 'models/topic';
import { Content, ProcessingState, PublicationState } from '../models/content';
import { Poll } from '../models/poll';
import { bossanovaDomain, request } from './api-shared';
import { PaginationData } from './common';
import { ParameterizedFilters } from '../contexts/content/filters';

export type FetchProps = {
  programId: number;
  search?: string;
  page?: number;
  pageSize?: number;
  publishedSince?: number;
  publishedBefore?: number;
  publicationState?: PublicationState[];
  processingState?: ProcessingState[];
  combinedSourcesMode?: number;
  sort?: SortColumn;
  sortDirection?: 'asc' | 'desc';
  after?: ContentPageCursor;
};

export type SortColumn =
  | 'updated_at'
  | 'published_at'
  | 'posted_at'
  | 'expired_at'
  | 'id'
  | 'created_at';

export type ContentCollectionData = {
  data: Array<ContentData>;
  after: ContentPageCursor;
  meta?: PaginationData;
};

export type Comment = {
  id: string;
  contentId: number;
  discourseId: number;
  programId: number;
  content: string;
  rawContent: string;
  replyToId: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  highlightedAt: string | null;
  replyCount: number;
  isLiked: boolean;
  likeCount: number;
  canEdit: boolean;
  canDelete: boolean;
  isReported: boolean;
  author: {
    id: number;
    avatar: {
      url: string | null;
      color: string;
    };
    name: string;
    privateProfile: boolean;
  };
  replies: Comment[];
};

export type CommentsData = {
  id: string;
  attributes: Comment;
  type: 'comment_posts';
};

export type CommentsCollectionData = {
  data: Array<CommentsData>;
  meta: PaginationData;
};

export type ContentData = {
  id: number;
} & (
  | {
      type: 'content_planner';
      attributes: Omit<Content, 'type'> & { contentChannels: Array<Topic> };
    }
  | {
      type: 'poll';
      attributes: Omit<Poll, 'type'>;
    }
);

const headers = {
  'Content-Type': 'application/json',
  'x-requested-with': 'XMLHttpRequest',
};

export interface ContentFetchProps extends FetchProps {
  includeTypes?: string[];
  contentChannelIds?: number[];
}

export const fetchContent = async (
  props: ContentFetchProps
): Promise<ContentCollectionData> => {
  const { programId, ...queryProps } = props;
  const query = qs.stringify(snakecaseKeys(queryProps), {
    arrayFormat: 'brackets',
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/planner?${query}`;
  const response = await request(url);

  if (response.status === 200)
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));

  throw new Error(`Error fetching content: ${response.status}`);
};

export const fetchUserContentSubmissions = async (
  programId: number,
  userId: number
): Promise<ContentCollectionData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/users/${userId}/content_submissions/count`;
  const response = await request(url, {
    headers,
  });

  if (response.status === 200)
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));

  throw new Error(`Error fetching content: ${response.status}`);
};

export type BulkParams = {
  type: string;
  includedIds: string[];
  excludedIds: string[];
  pollIds: string[];
  excludedPollIds: string[];
};

export type BulkQuery = FetchProps & ParameterizedFilters;

export const updateBulkStatus = async (
  programId: number,
  bulkParams: BulkParams,
  bulkQuery: BulkQuery,
  status: string
): Promise<string> => {
  const query = qs.stringify(snakecaseKeys(bulkQuery), {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk/status/${status}?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection: bulkParams })),
    headers,
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error bulk status: ${response.status}`);
};

export const bulkPublish = async (
  programId: number,
  bulkParams: BulkParams,
  bulkQuery: BulkQuery
): Promise<string> => {
  const query = qs.stringify(snakecaseKeys(bulkQuery), {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk_publish_operation?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection: bulkParams })),
    headers,
  });
  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }))
      .then((data) => {
        return data.data.jobId;
      });
  }
  if (response.status === 400) {
    return response.json().then((data) => {
      throw new Error(data.errors?.join(', '));
    });
  }
  throw new Error(`Error bulk publish: ${response.status}`);
};

export const bulkComplete = async (
  programId: number,
  bulkParams: BulkParams,
  bulkQuery: BulkQuery
): Promise<string> => {
  const query = qs.stringify(snakecaseKeys(bulkQuery), {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk/complete?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection: bulkParams })),
    headers,
  });
  if (response.status === 200) {
    return response.json().then((output) => camelcaseKeys(output));
  }
  throw new Error(`Error bulk complete: ${response.status}`);
};

export const duplicate = async (
  programId: number,
  postId: number
): Promise<ContentData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/${postId}/copy`;

  const response = await request(url, {
    method: 'POST',
    headers,
  });

  if (response.ok) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  throw new Error(`Error copying campaign: ${response.status}`);
};

type FetchPostCommentsProps = {
  programId: number;
  contentId: number;
  page?: number;
  filters?: string[];
};

export const fetchComments = async ({
  programId,
  contentId,
  ...params
}: FetchPostCommentsProps): Promise<CommentsCollectionData> => {
  const q = qs.stringify(snakecaseKeys(params), {
    arrayFormat: 'brackets',
  });

  let url = `${bossanovaDomain}/samba/programs/${programId}/contents/${contentId}/comments`;
  if (q) {
    url += `?${q}`;
  }

  const response = await request(url, {
    method: 'GET',
    headers,
  });

  if (response.ok) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }

  throw new Error(`Error fetching comments: ${response.status}`);
};

export const deleteCommentFlags = async ({
  programId,
  contentId,
  commentId,
}: {
  programId: number;
  contentId: number;
  commentId: string;
}): Promise<void> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/${contentId}/comments/${commentId}/reports`;

  const response = await request(url, {
    method: 'DELETE',
    headers,
  });

  if (response.ok) {
    return;
  }

  throw new Error(`Error deferring flags: ${response.status}`);
};

export const deleteComment = async ({
  programId,
  contentId,
  commentId,
}: {
  programId: number;
  contentId: number;
  commentId: string;
}): Promise<void> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/${contentId}/comments/${commentId}`;

  const response = await request(url, {
    method: 'DELETE',
    headers,
  });

  if (response.ok) {
    return;
  }

  throw new Error(`Error deleting comment: ${response.status}`);
};

export type EditableData = {
  id: string;
  type: string;
  attributes: {
    id: string;
    audiences: boolean;
    topics: boolean;
  };
};

export const fetchEditable = async (
  programId: number,
  content: Content
): Promise<EditableData> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/editable`;

  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ data: { attributes: content } })),
    headers,
  });

  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }));
  }

  throw new Error(`Error checking for content editability: ${response.status}`);
};

export type FetchBulkActionActiveJobOptions = {
  currentState: PublicationState;
  programId: number;
};
export type FetchBulkActionActiveJobData = {
  jobId: string;
};

export type FetchBulkActionActiveJobStatusOptions = {
  jobId?: string;
  programId: number;
};
export type ContentIdsStatus = {
  [key: string]: {
    status: PublicationState | 'processing';
  };
};
export type FetchBulkActionActiveJobStatusData = {
  jobId: number;
  contentIds: ContentIdsStatus;
  status: 'processing' | 'completed' | 'canceled';
  percentage: number;
  totalExecuted: number;
  errored: number;
  totalCount: number;
  nextState: PublicationState;
  userId: number;
  isCanceled: boolean;
};

export type FetchBulkActionActiveJobStatusResponse = {
  bulkContents: {
    contentId: number;
    contentStatus: 'processing' | PublicationState;
  }[];
  contentsCount: number;
  currentState: PublicationState;
  nextState: PublicationState;
  id: number;
  programId: number;
  isCanceled: boolean;
  jobStatus: 'processing' | 'completed' | 'cancelled';
  percentage: number;
  totalContentsProcessed: number;
  totalContentsErrored: number;
  totalSuccessfullyContentsProcessed: number;
  userId: number;
};

export const fetchBulkActionActiveJob = async (
  props: FetchBulkActionActiveJobOptions
): Promise<FetchBulkActionActiveJobData> => {
  const { programId, ...queryProps } = props;
  const query = qs.stringify(snakecaseKeys(queryProps), {
    arrayFormat: 'brackets',
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk_processing_job?${query}`;
  const response = await request(url);

  if (response.status === 200)
    return response.json().then((json) => camelcaseKeys(json, { deep: true }));

  throw new Error(`Error fetching bulk action active jobs: ${response.status}`);
};

export const fetchBulkActionActiveJobStatus = async (
  props: FetchBulkActionActiveJobStatusOptions
): Promise<FetchBulkActionActiveJobStatusData> => {
  const { programId, ...queryProps } = props;
  const query = qs.stringify(snakecaseKeys(queryProps), {
    arrayFormat: 'brackets',
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk_processing_job_details?${query}`;
  const response = await request(url);

  if (response.status === 200)
    return response
      .json()
      .then((json) => camelcaseKeys(json, { deep: true }))
      .then((data: { job: FetchBulkActionActiveJobStatusResponse }) => {
        const { job } = data;

        const totalExecuted = job.totalSuccessfullyContentsProcessed;
        const errored = job.totalContentsErrored;
        const totalCount = job.contentsCount;
        const percentage = Number(
          (((totalExecuted + errored) / totalCount) * 100).toFixed(0)
        );

        return {
          jobId: job.id,
          status: job.jobStatus,
          totalExecuted,
          errored,
          totalCount,
          nextState: job.nextState,
          userId: job.userId,
          isCanceled: job.isCanceled,
          contentIds: job.bulkContents.reduce((acc, curr) => {
            acc[`${curr.contentId}`] = { status: curr.contentStatus };
            return acc;
          }, {} as ContentIdsStatus),
          percentage,
        } as FetchBulkActionActiveJobStatusData;
      });

  throw new Error(
    `Error fetching bulk action active jobs status: ${response.status}`
  );
};

export const updateBulkArchive = async (
  programId: number,
  bulkParams: BulkParams,
  bulkQuery: BulkQuery
): Promise<string> => {
  const query = qs.stringify(snakecaseKeys(bulkQuery), {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/bulk_archive_operation?${query}`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ bulkSelection: bulkParams })),
    headers,
  });
  if (response.status === 200) {
    return response
      .json()
      .then((output) => camelcaseKeys(output, { deep: true }))
      .then((data) => {
        return data.data.jobId;
      });
  }
  if (response.status === 400) {
    return response.json().then((data) => {
      throw new Error(data.errors?.join(', '));
    });
  }
  throw new Error(`Error bulk archive: ${response.status}`);
};

export const cancelBulkProcess = async (
  {
    programId,
    jobId,
  }: {
    programId: number;
    jobId: string;
  },
  onSuccess: () => void
): Promise<void> => {
  const url = `${bossanovaDomain}/samba/programs/${programId}/contents/cancel_bulk_processing_job`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ jobId })),
    headers,
  });
  if (response.status === 200) {
    return response.json().then(() => {
      onSuccess?.();
    });
  }
  if (response.status === 400) {
    return response.json().then((data) => {
      throw new Error(data.errors?.join(', '));
    });
  }
  throw new Error(`Error bulk action cancellation: ${response.status}`);
};
