import { ComponentProps } from 'react';
import { PageHeader } from 'DesignSystem/Layout/Pages';

export const BOX_CONFIGURATION_PAGE_BREADCRUMBS: ComponentProps<
  typeof PageHeader
>['breadcrumbs'] = [
  { to: '../..', label: 'Configure' },
  { label: 'Box Knowledge Management' },
];

export const getBoxSettingsPageBreadcrumbs: (
  label: string
) => ComponentProps<typeof PageHeader>['breadcrumbs'] = (label) => [
  { to: '../../..', label: 'Configure' },
  { to: '..', label: 'Box Knowledge Management' },
  { label },
];
