import {
  CHANNEL_DISPLAY_NAMES,
  DeliveryChannelName,
} from '../../../models/journeys/journey';

export const formatNotificationMessage = (
  channels: DeliveryChannelName[],
  severity: 'info' | 'error'
): string => {
  const formattedChannels = new Intl.ListFormat().format(
    channels.map((name) => CHANNEL_DISPLAY_NAMES[name])
  );

  if (severity === 'info')
    return `Test successfully sent to: ${formattedChannels}`;
  return `Test failed to send to: ${formattedChannels}`;
};
