import { ComponentProps } from 'react';
import { usePermissions } from 'contexts/permissions';
import { PageHeader } from 'DesignSystem/Layout/Pages';

export const useBoxConfigurationPageTabs: () => ComponentProps<
  typeof PageHeader
>['tabs'] = () => {
  const { permissions } = usePermissions();

  const tabs = [
    { to: 'manage-folders', label: 'Manage Folders' },
    { to: 'manage-content', label: 'Manage Box Content' },
  ];

  if (permissions.boxKnowledgeManagementConfigureAppAccess) {
    tabs.push({ to: 'settings', label: 'Box Settings' });
  }

  return tabs;
};
