import React from 'react';
import { LitmusRole } from 'models/role';
import { color, Subheading } from 'DesignSystem/Typography';
import { TextInput } from 'shared/TextInput';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { Section } from 'DesignSystem/Form';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Spinner } from '../Shared/Spinner';
import { PermissionRow } from './PermissionRow';
import { enabledByDefault } from './constant';

import styles from './form.module.css';
import roleStyles from './roles.module.css';
import { useUser } from '../../../../contexts/user';
import { usePermissionFilters } from './usePermissionFilter';

type PropsType = {
  role: Partial<LitmusRole>;
  section?: string;
  onChange: (data: Partial<LitmusRole>) => void;
};

export const Form: React.FC<PropsType> = ({ role, section, onChange }) => {
  const { id: programId } = useProgram();
  const { id: userId } = useUser();

  const { data: insightsPlusEnabledForProgram } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration'
  );

  const { data: insightsPlusOverrideEnabledForUser } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration.Override',
    userId
  );

  const { data: captionTranscriptionEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranscription'
  );

  const { filterPermissions } = usePermissionFilters(programId);

  const insightsPlusEnabled =
    insightsPlusEnabledForProgram?.value ||
    insightsPlusOverrideEnabledForUser?.value;

  const { isLoading, data: permissions } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'secondary',
    hidden: false,
  });

  const sortedPermissions = permissions.sort((a, b) => {
    if (a.sequence < b.sequence) return -1;
    if (a.sequence > b.sequence) return 1;
    return 0;
  });

  const mainPermissions = sortedPermissions
    .filter((p) => p.parent === null)
    .filter((p) => {
      if (p.name === 'journeys') return false;

      if (p.name === 'insights_plus_access') {
        return Boolean(insightsPlusEnabled);
      }

      if (p.name === 'manage_language_controls') {
        return !!captionTranscriptionEnabled?.value;
      }

      return true;
    });
  const subPermissionsMap = new Map<string, LitmusRole[]>();
  sortedPermissions.forEach((p) => {
    if (!p.parent) return;

    if (subPermissionsMap.has(p.parent)) {
      subPermissionsMap.set(p.parent, [
        ...(subPermissionsMap.get(p.parent) as LitmusRole[]),
        p,
      ]);
    } else {
      subPermissionsMap.set(p.parent, [p]);
    }
  });

  const filteredSubPermissionsMap = new Map(subPermissionsMap);
  filteredSubPermissionsMap.forEach((subPermissions, key) => {
    const filteredSubPermissions = filterPermissions(subPermissions);
    filteredSubPermissionsMap.set(key, filteredSubPermissions);
  });

  function getRemovedPermissions(
    permissionName: string,
    removedPermissions: string[] = []
  ): string[] {
    removedPermissions.push(permissionName);
    if (!subPermissionsMap.has(permissionName)) {
      return removedPermissions;
    }
    subPermissionsMap
      .get(permissionName)
      ?.forEach((p) => getRemovedPermissions(p.name, removedPermissions));
    return removedPermissions;
  }

  function getSelectedPermissions(permissionName: string): { name: string }[] {
    const selected = [{ name: permissionName }];
    filteredSubPermissionsMap.get(permissionName)?.forEach((p) => {
      if (enabledByDefault.includes(p.name)) selected.push({ name: p.name });
    });
    return selected;
  }

  function onPermissionsChange(
    permissionName: string,
    selected: true | undefined
  ) {
    let aliases = role.aliases || [];
    if (selected) {
      aliases = [...aliases, ...getSelectedPermissions(permissionName)];
    } else {
      const removedPermissionNames = getRemovedPermissions(permissionName);
      aliases = aliases.filter(
        (alias) => !removedPermissionNames.includes(alias.name)
      );
    }
    onChange({ aliases });
  }

  return (
    <>
      {(section === 'general' || section === undefined) && (
        <Section title="General" className={roleStyles.section}>
          <Subheading bold>Name</Subheading>
          <TextInput
            className={styles.textField}
            value={role.title}
            onChange={(value) => onChange({ title: value })}
            maximum={200}
          />
          <Subheading bold>Description</Subheading>
          <TextInput
            className={styles.textField}
            value={role.description}
            onChange={(value) => onChange({ description: value })}
          />
        </Section>
      )}

      {(section === 'permissions' || section === undefined) && (
        <Section title="Permissions" className={roleStyles.section}>
          <Subheading color={color.gray60}>
            Defines what parts of Studio platform the user will have access to
          </Subheading>
          <div>
            {isLoading && <Spinner />}
            {mainPermissions.map((permission) => (
              <PermissionRow
                key={permission.name}
                permission={permission}
                on={(p) => {
                  return (
                    role.aliases?.some((alias) => alias.name === p.name) ||
                    false
                  );
                }}
                onChange={onPermissionsChange}
                subPermissionsMap={Object.fromEntries(
                  filteredSubPermissionsMap
                )}
              />
            ))}
          </div>
        </Section>
      )}
    </>
  );
};
