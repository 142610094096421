import { DateTime, Interval } from 'luxon';
import { Option } from 'models/insight/json/filterJson';

export const generateDateValues = (
  timeUnit: 'year' | 'month' | 'quarter'
): Option[] => {
  const start = DateTime.now().startOf('month').minus({ months: 24 });
  const lastTwoYears = Interval.after(start, {
    years: 2,
  });
  let lastTwoYearsInterval;
  if (timeUnit === 'year') {
    lastTwoYearsInterval = Interval.fromDateTimes(
      DateTime.fromObject({
        month: lastTwoYears.start?.month,
        year: lastTwoYears.start?.year,
      }),
      DateTime.fromObject({
        month: lastTwoYears.end?.month,
        year: lastTwoYears.end?.endOf('year')?.year
          ? lastTwoYears.end?.endOf('year')?.year + 1
          : 0,
      })
    ).splitBy({ years: 1 });
  } else if (timeUnit === 'quarter') {
    lastTwoYearsInterval = lastTwoYears.splitBy({ months: 3 });
  } else {
    lastTwoYearsInterval = lastTwoYears.splitBy({ months: 1 });
  }
  return lastTwoYearsInterval.map((interval) => {
    const d = interval.start;
    let label;
    if (timeUnit === 'month') {
      label = d?.toLocaleString({ month: 'long', year: 'numeric' }) ?? '';
    } else if (timeUnit === 'year') {
      label = d?.year?.toString() ?? '';
    } else {
      label = `Q${d?.quarter} ${d?.year}`;
    }
    return {
      value: d?.toLocaleString(DateTime.DATE_SHORT) ?? '',
      label: label ?? '',
    };
  });
};
