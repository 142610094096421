import { RouteComponentProps, useNavigate } from '@reach/router';
import { isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { FormPage } from 'DesignSystem/Layout/Pages';
import {
  useBulkCreateBoxFolderAudienceMapping,
  useCreateBoxFolder,
} from 'hooks/box-knowledge-mangement';
import { Audience } from 'models/audience';
import { BoxIntegrationData } from 'models/box-integration';
import { ConflictError } from 'services/Errors/ConflictError';
import { WithPermission } from 'shared/WithPermission';
import {
  BoxFolderForm,
  BoxFolderFormContext,
  useCheckBoxFolderConflict,
} from '../shared/Form/Folder';

export const NewBoxFolder: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const navigate = useNavigate();
  const {
    createAsync: createBoxFolder,
    isLoading: isCreatingBoxFolder,
  } = useCreateBoxFolder();
  const {
    bulkCreateAsync: bulkCreateFolderAudienceMapping,
    isLoading: isCreatingFolderAudienceMapping,
  } = useBulkCreateBoxFolderAudienceMapping();

  const { setFlashMessage } = useFlashMessage();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [boxFolder, setBoxFolder] = useState<BoxIntegrationData>();

  const {
    check: checkBoxFolderConflict,
    isLoading: isCheckingConflict,
    errorMessage: boxFolderErrorMessage,
  } = useCheckBoxFolderConflict(boxFolder);

  const onSave = useCallback(async () => {
    if (!name || !audiences.length || !boxFolder) {
      return;
    }

    try {
      const folder = await createBoxFolder({
        programId,
        name,
        description,
        boxFolderId: Number(boxFolder.resource_id),
      });

      await bulkCreateFolderAudienceMapping({
        programId,
        folderId: folder.boxFolderId,
        audienceIds: audiences
          .filter(
            (audience): audience is Audience & Required<Pick<Audience, 'id'>> =>
              !!audience.id
          )
          .map((audience) => audience.id),
      });
    } catch (err) {
      if (
        err instanceof ConflictError ||
        (isAxiosError(err) && err.response?.status === 409)
      ) {
        setFlashMessage({
          severity: 'error',
          message:
            'This Box folder has already been linked to a folder in Studio. Please select a different folder.',
        });
        return;
      }

      setFlashMessage({
        severity: 'error',
        message: 'There was an error creating the folder. Please try again.',
      });
      throw err;
    }

    await navigate('..');
  }, [
    audiences,
    boxFolder,
    bulkCreateFolderAudienceMapping,
    createBoxFolder,
    description,
    name,
    navigate,
    programId,
    setFlashMessage,
  ]);

  useEffect(() => {
    if (boxFolder) {
      checkBoxFolderConflict();
    }
  }, [boxFolder, checkBoxFolderConflict]);

  const hasError =
    !name || !audiences.length || !boxFolder || !!boxFolderErrorMessage;

  return (
    <WithPermission
      permissions={['configureBoxKnowledgeManagementAccess']}
      operation="all"
    >
      <FormPage
        breadcrumbs={[
          { to: '../../..', label: 'Configure' },
          { to: '../..', label: 'Box Knowledge Management' },
          { label: 'Create Folder' },
        ]}
        actions={[
          {
            label: 'Save',
            disabled: hasError || isCheckingConflict,
            isLoading: isCreatingBoxFolder || isCreatingFolderAudienceMapping,
            onClick: onSave,
          },
        ]}
        title="Create Folder"
      >
        <BoxFolderFormContext.Provider
          value={{
            name,
            description,
            audiences,
            boxFolder,
            savedBoxFolderName: undefined,
            onNameChange: setName,
            onDescriptionChange: setDescription,
            onAudiencesChange: setAudiences,
            onBoxFolderChange: setBoxFolder,
            isCheckingBoxFolderConflict: isCheckingConflict,
            boxFolderErrorMessage,
          }}
        >
          <BoxFolderForm />
        </BoxFolderFormContext.Provider>
      </FormPage>
    </WithPermission>
  );
};
