import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { Button, FormSection } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import * as Text from 'DesignSystem/Typography';
import { useLanguageControls } from 'hooks/language-controls/useLanguageControls';
import {
  CONTROL_TYPE_DO_NOT_TRANSLATE,
  CONTROL_TYPE_REMOVE,
  CONTROL_TYPE_REPLACE,
} from 'models/language-controls/language-control';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import { LanguageSelect } from './LanguageSelect';
import { RuleTypeSelect } from './RuleTypeSelect';
import { TermInput } from './TermInput';
import styles from './language-control-edit-page.module.css';

export const LanguageControlEditPage: React.FC<RouteComponentProps<{
  id: 'new' | number;
}>> = ({ id = 'new', navigate }) => {
  const {
    languageControl,
    isLoading,
    isPersisting,
    isDisabled,
    onChange,
    save,
  } = useLanguageControls({ id });

  if (isLoading) return null;

  const displayTitle = languageControl.id ? 'Edit Rule' : 'Create Rule';

  const saveButton = (
    <Button
      disabled={isDisabled || isPersisting}
      onClick={() =>
        save({
          onSuccess: () => navigate?.('../'),
        })
      }
      label={
        isPersisting ? (
          <>
            Save&nbsp;
            <LoadingSpinner size="xsmall" />
          </>
        ) : (
          'Save'
        )
      }
    />
  );

  return (
    <WithPermission permissions={['languageControlsAccess']}>
      <FormPage
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Language Controls', to: '..' },
          { label: displayTitle },
        ]}
        title={displayTitle}
        actionsOverride={saveButton}
      >
        <FormSection>
          <SubSection
            title="Source Language"
            description="Select the source language that this rule applies to"
          >
            <LanguageSelect
              value={languageControl?.locale}
              onChange={(data) =>
                onChange({
                  ...languageControl,
                  ...data,
                })
              }
            />
          </SubSection>
          <SubSection
            title="Rule Type"
            description="Select the type of rule you would like to create"
          >
            <RuleTypeSelect
              value={languageControl.controlType}
              onChange={(data) =>
                onChange({
                  ...languageControl,
                  ...data,
                })
              }
            />
          </SubSection>
          {languageControl.controlType === CONTROL_TYPE_DO_NOT_TRANSLATE && (
            <SubSection
              title="Target Term"
              description="When this term comes up, it will not be translated"
            >
              <TermInput
                value={languageControl.target}
                property="target"
                onChange={(data) =>
                  onChange({
                    ...languageControl,
                    ...data,
                  })
                }
              />
            </SubSection>
          )}
          {languageControl.controlType === CONTROL_TYPE_REMOVE && (
            <SubSection
              title="Target Term"
              description="When this term comes up, it will be removed"
            >
              <TermInput
                value={languageControl.target}
                property="target"
                onChange={(data) =>
                  onChange({
                    ...languageControl,
                    ...data,
                  })
                }
              />
            </SubSection>
          )}
          {languageControl.controlType === CONTROL_TYPE_REPLACE && (
            <>
              <SubSection
                title="Original Term"
                description="When this term comes up, it will be replaced (Case sensitive)"
              >
                <TermInput
                  value={languageControl.target}
                  property="target"
                  onChange={(data) =>
                    onChange({
                      ...languageControl,
                      ...data,
                    })
                  }
                />
              </SubSection>
              <SubSection
                title="Replacement Term"
                description="The original term will be replaced with this term"
              >
                <TermInput
                  value={languageControl.replacement}
                  property="replacement"
                  onChange={(data) => {
                    onChange({
                      ...languageControl,
                      ...data,
                    });
                  }}
                />
              </SubSection>
            </>
          )}
        </FormSection>
      </FormPage>
    </WithPermission>
  );
};

const SubSection: React.FC<{
  title: string;
  description: string;
}> = ({ children, title, description }) => (
  <section className={styles.subSection}>
    <div className={styles.subsectionTitle}>
      <Text.Subheading block bold>
        {title}
      </Text.Subheading>
    </div>
    <div className={styles.subsectionDescription}>
      <Text.Body block color={Text.color.gray50}>
        {description}
      </Text.Body>
    </div>
    {children}
  </section>
);
