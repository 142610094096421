import { Duration } from 'luxon';

export const displayLargestTimeInterval = (duration: Duration): string => {
  if (!duration.isValid) {
    return '';
  }

  if (duration.days !== 0) {
    return `${duration.days} day${duration.days === 1 ? '' : 's'} ago`;
  }
  if (duration.hours !== 0) {
    return `${duration.hours} hour${duration.hours === 1 ? '' : 's'} ago`;
  }
  if (duration.minutes !== 0) {
    return `${duration.minutes} minute${duration.minutes === 1 ? '' : 's'} ago`;
  }
  return 'now';
};
