import { Button } from 'DesignSystem/Form';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DismissType, Box } from 'DesignSystem/Components';
import {
  Gray10PillClass,
  GreenTintDarkPillClass,
} from 'DesignSystem/Components/Pill';
import { Pills } from 'DesignSystem/Components/Pills';
import { Body, Caption, color } from 'DesignSystem/Typography';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { useUniqueId } from 'hooks/useUniqueId';
import { useEffect, useState } from 'react';
import { CircleCheck } from 'shared/icons';
import { NotificationCards } from '../Notifications/NotificationCard';
import { notificationTextPreview } from '../Notifications/notificationTextPreview';
import { useNotifications } from '../Notifications/useNotifications';
import { Divisor, SettingsCard, ContentTitle } from '../SettingsCard';
import { InfoTooltip } from '../Shared/InfoTooltip';
import { ChangeChannels, channelIcons } from './ChangeChannels';
import {
  useChannelSettings,
  DeliveryChannelOption,
} from './useChannelSettings';
import styles from './index.module.css';

interface DirectDeliverySectionProps {
  disabled?: boolean;
}

export const ChannelsSection: React.FC<DirectDeliverySectionProps> = ({
  disabled,
}) => {
  const {
    deliveryChannelsOptions,
    orchestrateDeliveryForYou,
    turnOffAndHideForYou,
  } = useChannelSettings();

  const {
    settings: { deliveryChannels, includeInForYou, emailSenderAlias },
    fields: {
      includeInForYou: { set: setIncludeInForYou },
    },
  } = useSettings();
  const { firstNotification } = useNotifications();

  const notificationCenterEnabled = useNotificationCenterEnabled();
  const {
    assistantText,
    emailPreview,
    emailSubject,
    pushText,
  } = notificationTextPreview(firstNotification);

  const { data: defaultAddress } = useDefaultEmailAddress();

  const changeChannels = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => (
    <ChangeChannels
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  const renderPill = (value: DeliveryChannelOption) => <>&nbsp;{value.label}</>;
  const pillIcons = (value: DeliveryChannelOption) => channelIcons(value.name);

  const enabledChannels = deliveryChannelsOptions.filter(
    (c) => deliveryChannels[c.name]
  );

  useEffect(() => {
    if (
      turnOffAndHideForYou &&
      (includeInForYou === undefined || includeInForYou)
    )
      setIncludeInForYou(false);
  });

  const hasChannels = enabledChannels.length > 0;

  const forYouChecked =
    !turnOffAndHideForYou && includeInForYou && orchestrateDeliveryForYou;
  const emailChannel = deliveryChannels?.email;
  const pushChannel = deliveryChannels?.push;
  const assistantChannel = deliveryChannels?.assistant;

  const [editing, setEditing] = useState(false);

  const defaultAddressMessage = defaultAddress
    ? `${defaultAddress.senderName} <${defaultAddress.senderEmail}>`
    : 'No default alias selected';

  const featurePills = [
    { id: 1, visible: includeInForYou, label: "Include in 'For You'" },
    {
      id: 2,
      visible: notificationCenterEnabled && assistantChannel,
      label: (
        <>
          Mark as important:&nbsp;
          <Caption bold>
            {firstNotification.notificationCenterMarkAsImportant ? 'ON' : 'OFF'}
          </Caption>
        </>
      ),
    },
  ];

  return (
    <>
      <Divisor />
      <SettingsCard
        dataTest="channels-settings"
        isEditing={editing}
        showEditButton={hasChannels || forYouChecked}
        editRenderProp={changeChannels}
        onEditingToggle={(value: boolean) => {
          setEditing(value);
        }}
        disabled={disabled}
      >
        {(toggleEditing: () => void) => (
          <>
            <Box margin={[0, 0, 0, 0]}>
              <ContentTitle>
                Channels
                <InfoTooltip message="Unless only one channel is selected, the orchestration engine will optimize which enabled channels to leverage for each campaign." />
              </ContentTitle>
              {!forYouChecked && !hasChannels && !editing && (
                <Box>
                  <Button
                    onClick={toggleEditing}
                    label="Add Channels"
                    disabled={disabled}
                  />
                </Box>
              )}

              {(hasChannels || forYouChecked) && (
                <>
                  <Box>
                    {hasChannels ? (
                      <Pills
                        render={renderPill}
                        pillIcon={pillIcons}
                        values={enabledChannels}
                        pillClassName={Gray10PillClass}
                        small
                        dataTest="channel-pills"
                        padding={[0, 8]}
                      />
                    ) : (
                      <div>
                        <Body italic color={color.gray60}>
                          No channels selected
                        </Body>
                      </div>
                    )}
                  </Box>

                  {featurePills.some(({ visible }) => visible) && (
                    <Box margin={[hasChannels ? 10 : 20, 0, 0, 0]}>
                      <Pills
                        values={featurePills.filter(({ visible }) => visible)}
                        render={({ label }) => label}
                        pillIcon={() => <CircleCheck />}
                        pillClassName={GreenTintDarkPillClass}
                        small
                        padding={[0, 8]}
                      />
                    </Box>
                  )}
                  <div className={styles.NotificationContainer}>
                    {emailChannel && (
                      <Box margin={[30, 0, 0, 0]}>
                        <ContentTitle>Email Alias</ContentTitle>
                        <Box margin={[16, 0, 0, 0]}>
                          <Body>
                            {emailSenderAlias
                              ? `${emailSenderAlias.senderName} <${emailSenderAlias?.senderEmail}>`
                              : defaultAddressMessage}
                          </Body>
                        </Box>
                      </Box>
                    )}
                    {emailChannel && (
                      <NotificationWrapper title="Email Subject Line and Preview">
                        <NotificationCards.Email
                          emailPreview={emailPreview}
                          emailSubject={emailSubject}
                        />
                      </NotificationWrapper>
                    )}
                    {pushChannel && (
                      <NotificationWrapper title="Push Notification Message">
                        <NotificationCards.Push pushText={pushText} />
                      </NotificationWrapper>
                    )}
                    {assistantChannel && notificationCenterEnabled && (
                      <NotificationWrapper title="Notification Center Title">
                        <NotificationCards.Assistant
                          assistantText={assistantText}
                        />
                      </NotificationWrapper>
                    )}
                  </div>
                </>
              )}
            </Box>
          </>
        )}
      </SettingsCard>
    </>
  );
};

function NotificationWrapper({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) {
  const id = useUniqueId();

  return (
    <Box aria-labelledby={id} margin={[0, 0, 0, 0]}>
      <ContentTitle id={id}>{title}</ContentTitle>
      {children}
    </Box>
  );
}
