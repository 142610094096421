import * as React from 'react';
import cx from 'classnames';
import * as Text from 'DesignSystem/Typography';
import { useUniqueId } from 'hooks/useUniqueId';
import styles from './forms.module.css';
import { Button } from './InputElements';

export const Section: React.FC<{
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  href?: string;
  button?: React.ReactNode;
  className?: string;
  ariaLabelledBy?: string;
  headerButtonLabel?: string;
}> = ({
  children,
  title,
  description,
  href,
  button,
  className,
  ariaLabelledBy,
  headerButtonLabel = 'Edit',
}) => {
  const uuid = useUniqueId();

  return (
    <section
      className={cx(styles.FormSection, className)}
      aria-labelledby={ariaLabelledBy || uuid}
    >
      {(title || href) && (
        <header>
          <div>
            {title && (
              <div className={styles.title}>
                <Text.Heading bold id={uuid}>
                  {title}
                </Text.Heading>
              </div>
            )}
            {description && (
              <Text.Body block color={Text.color.gray70}>
                {description}
              </Text.Body>
            )}
          </div>
          {href && <Button secondary label={headerButtonLabel} href={href} />}
          {button}
        </header>
      )}
      {typeof children !== 'undefined' && (
        <div className={styles.body}>{children}</div>
      )}
    </section>
  );
};

export const Subsection: React.FC<{
  title: string;
  description?: React.ReactNode;
  divider?: boolean;
}> = ({ children, title, description, divider = true }) => (
  <section
    className={cx(styles.FormSubsection, {
      [styles.FormSubsectionNoDivider]: !divider,
    })}
  >
    <Text.Subheading block bold>
      {title}
    </Text.Subheading>
    {description && (
      <Text.Body block color={Text.color.gray70}>
        {description}
      </Text.Body>
    )}
    {children}
  </section>
);
