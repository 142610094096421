import { useProgram } from 'contexts/program';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { fetchDataJobMetrics } from '../services/api-insights';

interface IUseDataJobMetrics {
  results?: null | DataJobMetrics;
  isLoading: boolean;
}

type DataJobMetrics = {
  dataFreshnessDate: DateTime;
  workbook?: string;
};

export const useDataJobMetrics = (workbookId: string): IUseDataJobMetrics => {
  const { id: programId } = useProgram();
  const [results, setResults] = useState<DataJobMetrics | null>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading || workbookId === undefined || results !== undefined) {
      return;
    }

    setIsLoading(true);
    fetchDataJobMetrics({
      programId,
      workbookId,
    })
      .then((res) => {
        const { data } = res;
        if (data === undefined) {
          setResults(null);
        } else {
          const freshnessDate = DateTime.fromISO(data.data_freshness_date, {
            zone: 'utc',
          });
          if (freshnessDate.isValid) {
            setResults({
              workbook: data.dashboard,
              dataFreshnessDate: freshnessDate,
            });
          } else {
            setResults(null);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setResults(null);
      });
  }, [isLoading, workbookId, programId, results, setIsLoading]);

  return {
    results,
    isLoading,
  };
};
