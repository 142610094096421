import { useState } from 'react';

import {
  fetchComments,
  deleteCommentFlags,
  deleteComment,
  CommentsCollectionData,
} from 'services/api-content';
import { QueryError, QueryResponse } from 'hooks/common';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from 'react-query';

type FetchCommentsProps = {
  programId: number;
  contentId: number;
};

type DeleteCommentReportProps = {
  programId: number;
  contentId: number;
  commentId: string;
};

type DeleteCommentProps = {
  programId: number;
  contentId: number;
  commentId: string;
};

type MutationResponse<T> = {
  mutate: (data: T) => void;
  isLoading: boolean;
  errorMessage?: string;
  activeId: number | string | null;
};

export const useCommentQuery = ({
  contentId,
  programId,
}: FetchCommentsProps): QueryResponse<CommentsCollectionData> & {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CommentsCollectionData, Error>>;
} => {
  const { refetch, isLoading, error, data } = useQuery<
    CommentsCollectionData,
    Error
  >(
    ['comment_posts', { contentId, programId }],
    () => fetchComments({ contentId, programId }),
    { retry: false }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
    refetch,
  };
};

export const useDeferFlagsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
}): MutationResponse<DeleteCommentReportProps> => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const { mutate, isLoading, error } = useMutation(
    ['comments/defer_flags'],
    deleteCommentFlags,
    {
      onSuccess,
      onError,
      onMutate: ({ commentId }) => setActiveId(commentId),
      onSettled: () => setActiveId(null),
    }
  );
  return { mutate, activeId, isLoading, errorMessage: error?.message };
};

export const useDeleteCommentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: QueryError) => void;
}): MutationResponse<DeleteCommentProps> => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const { mutate, isLoading, error } = useMutation(
    ['delete_comment'],
    deleteComment,
    {
      onSuccess,
      onError,
      onMutate: ({ commentId }) => setActiveId(commentId),
      onSettled: () => setActiveId(null),
    }
  );
  return { mutate, activeId, isLoading, errorMessage: error?.message };
};
