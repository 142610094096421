import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body, border, Caption } from 'DesignSystem/Typography';
import { Notification } from 'models/notification';
import { Icon } from '@socialchorus/shared-ui-components';
import { VisuallyHidden } from 'shared/VisuallyHidden';
import { useUniqueId } from 'hooks/useUniqueId';
import { SubjectLinePreview } from './SubjectLinePreview';
import styles from './NotificationCard.module.css';
import { notificationTextPreview } from './notificationTextPreview';

type NotificationCardProps = {
  notification: Notification;
  cardTitle?: string | React.ReactNode;
  disableEmail?: boolean;
  disablePush?: boolean;
  disableAssistant?: boolean;
};

export const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  cardTitle = undefined,
  disableEmail = false,
  disablePush = false,
  disableAssistant = false,
}) => {
  const {
    emailSubject,
    emailPreview,
    pushText,
    assistantText,
  } = notificationTextPreview(notification);

  if (disableEmail && disablePush) return null;

  const title =
    typeof cardTitle === 'string' ? <Body>{cardTitle}</Body> : cardTitle;

  return (
    <Box margin={[20, 0, 0, 0]}>
      {cardTitle && <Box margin={[0, 0, 10, 0]}>{title}</Box>}
      <div className={styles.NotificationContainer}>
        {!disableEmail && (
          <EmailChannelNotification
            emailSubject={emailSubject}
            emailPreview={emailPreview}
          />
        )}
        {!disableAssistant && (
          <AssistantChannelNotification assistantText={assistantText} />
        )}
        {!disablePush && <PushChannelNotification pushText={pushText} />}
      </div>
    </Box>
  );
};

export const NotificationCards = {
  Email: EmailChannelNotification,
  Assistant: AssistantChannelNotification,
  Push: PushChannelNotification,
};

interface EmailChannelNotificationProps {
  emailSubject: string;
  emailPreview: string;
}

function EmailChannelNotification({
  emailSubject,
  emailPreview,
}: EmailChannelNotificationProps): JSX.Element {
  return (
    <IndividualChannelNotification
      label="Email"
      title={emailSubject}
      text={emailPreview}
      leftWidget={<Icon size={32}>mail</Icon>}
    />
  );
}

interface PushChannelNotificationProps {
  pushText: string;
}

function PushChannelNotification({
  pushText,
}: PushChannelNotificationProps): JSX.Element {
  return (
    <IndividualChannelNotification
      label="Push"
      title={pushText}
      leftWidget={<Icon size={32}>phone_iphone</Icon>}
    />
  );
}

interface AssistantChannelNotificationProps {
  assistantText: string;
}

function AssistantChannelNotification({
  assistantText,
}: AssistantChannelNotificationProps): JSX.Element {
  return (
    <IndividualChannelNotification
      label="Notification Center"
      title={assistantText}
      leftWidget={<Icon size={32}>notifications</Icon>}
    />
  );
}

interface IndividualChannelNotificationProps {
  label: string;
  title: string;
  text?: string;
  leftWidget: React.ReactNode;
}

function IndividualChannelNotification({
  label,
  title,
  text,
  leftWidget,
}: IndividualChannelNotificationProps) {
  const componentId = useUniqueId();
  const visuallyHiddenLabelId = `${componentId}__label`;
  return (
    <Box
      height="64px"
      border={[border.solid, border.width1, border.radius8, border.gray10]}
      style={{ display: 'flex' }}
      aria-labelledby={visuallyHiddenLabelId}
    >
      <VisuallyHidden id={visuallyHiddenLabelId}>{label}</VisuallyHidden>
      <Box className={styles.Icon} margin={['auto', 20.5]}>
        {leftWidget}
      </Box>
      <Box
        className={styles.DescriptionWrapper}
        margin={['auto', 20.5, 'auto', 0]}
      >
        <Body>
          <SubjectLinePreview subjectText={title} />
        </Body>

        {text && (
          <Box className={styles.Description}>
            <Caption>
              <SubjectLinePreview subjectText={text} />
            </Caption>
          </Box>
        )}
      </Box>
    </Box>
  );
}
